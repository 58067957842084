import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

// import ui components
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// import page components
import AuthBox from '../components/auth/AuthBox';
import LoginForm from '../components/login/LoginForm';
import LoginOTP from '../components/login/LoginOTP';
import LoginPin from '../components/login/LoginPin';
import MudahLogoPair from '../components/MudahLogoPair';

// import static assets
import XenditLogo from '../../assets/xendit-logo.png';

// import auth context
import { useUserAuth } from '../../context/UserAuthContext';

const steps = ['Login Form', 'OTP', 'Pin'];

function getStepContent(step, handleNext, userPhoneNumber, setUserPhoneNumber) {
  switch (step) {
    case 0:
      return (
        <LoginForm
          handleNext={handleNext}
          setUserPhoneNumber={setUserPhoneNumber}
        />
      );
    case 1:
      return (
        <LoginOTP handleNext={handleNext} userPhoneNumber={userPhoneNumber} />
      );
    case 2:
      return <LoginPin />;
    default:
      throw new Error('Unknown step');
  }
}

const Login = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [userPhoneNumber, setUserPhoneNumber] = useState('');

  const { user, pinAuthenticated } = useUserAuth();

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  if (user && pinAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <AuthBox>
      {activeStep === steps.length ? (
        <Navigate to="/" />
      ) : (
        <>
          <MudahLogoPair />
          <Typography
            component="h1"
            variant="h4"
            align="left"
            sx={{
              mt: 2,
              fontWeight: '600',
            }}
          >
            Masuk ke dasbor kamu
          </Typography>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="flex-end"
            sx={{
              height: '60vh',
            }}
          >
            <Grid item container justifyContent="center" alignItems="center">
              {getStepContent(
                activeStep,
                handleNext,
                userPhoneNumber,
                setUserPhoneNumber
              )}
            </Grid>
            {activeStep !== 1 && activeStep !== 2 && (
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ mt: 'auto', width: 1 }}
              >
                <Grid item>
                  <Typography variant="body2">Bekerja sama dengan: </Typography>
                </Grid>
                <Grid item>
                  <img src={XenditLogo} alt="Xendit Logo" />
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      )}
      <div id="sign-in-button"></div>
    </AuthBox>
  );
};

export default Login;
