import React from 'react';

// mui components
import {
  Box,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const TncSk = ({
  tncModalOpen,
  handleTncModalClose,
  handleCloseAllTncModals,
}) => {
  return (
    <Modal open={tncModalOpen} onClose={handleTncModalClose}>
      <Paper
        sx={{
          mx: 'auto',
          mt: '10vh',
          p: 4,
          width: { xs: '80vw', md: 400 },
          backgroundColor: 'primary.mudahWhite',
        }}
      >
        <Stack justifyContent="flex-end">
          <IconButton
            aria-label="close"
            sx={{ ml: 'auto' }}
            onClick={handleCloseAllTncModals}
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        <Typography variant="h6" component="h2" fontWeight="bold">
          Syarat dan Ketentuan
        </Typography>

        <Typography
          overflow="auto"
          paragraph={true}
          variant="body2"
          sx={{
            maxHeight: '350px',
            mt: 2,
          }}
        >
          Kami, PT Hidup Mudah Indonesia (“Mudah Indonesia”), perseroan terbatas
          yang didirikan berdasarkan hukum di Negara Republik Indonesia, dalam
          Syarat dan Ketentuan Penggunaan Layanan ini bertindak sebagai Mudah
          Indonesia. Halaman ini menjelaskan tentang syarat dan ketentuan
          penggunaan situs web dan platform mudahindonesia.com, baik sebagai
          pengunjung maupun pengguna (Anda). Seluruh pengunjung dan pengguna
          wajib membaca dan mencermati Syarat dan Ketentuan Penggunaan
          Penggunaan Layanan ini. Dengan mengunjungi dan/atau menggunakan Mudah
          Indonesia, maka Anda dinyatakan telah memahami dan menyepakati semua
          Syarat dan Ketentuan Layanan di bawah ini. Apabila Anda tidak
          menyetujui salah satu, sebagian atau seluruh isi dari Syarat dan
          Ketentuan Layanan ini, maka Anda tidak diperkenankan untuk menggunakan
          layanan di Mudah Indonesia.
          <br></br>
          <br></br>
          <Box component="span" fontWeight="bold">
            Kebijakan Privasi
          </Box>
          <br></br>
          <br></br>
          Kebijakan Privasi ini menjelaskan kebijakan dan prosedur kami mengenai
          pengumpulan, penggunaan, dan pengungkapan informasi Anda saat Anda
          menggunakan Layanan dan memberitahu Anda tentang hak privasi Anda dan
          bagaimana undang-undang yang berlaku melindungi Anda. Kami menggunakan
          data Pribadi Anda untuk menyediakan dan meningkatkan Layanan. Dengan
          menggunakan Layanan, Anda menyetujui pengumpulan dan penggunaan
          informasi sesuai dengan Kebijakan Privasi ini.
          <br></br>
          <br></br>
          <Box component="span" fontWeight="bold">
            Definisi
          </Box>
          <br></br>
          <br></br>
          Untuk tujuan Kebijakan Privasi ini:
          <br></br>
          <br></br>
          1) Akun berarti akun unik yang dibuat agar Anda dapat mengakses
          Layanan kami atau bagian dari Layanan kami. 2) Perusahaan (disebut
          sebagai “Perusahaan” atau “Kami” dalam Perjanjian ini) mengacu pada PT
          Hidup Mudah Indonesia, Perkantoran Tanjung Mas Raya Blok B.1 Nomor 44,
          Jalan Tanjung Barat Raya, Tanjung Barat, Jagakarsa. Jakarta Selatan,
          DKI Jakarta, 12530. 3) Cookie adalah file kecil yang ditempatkan di
          komputer, perangkat seluler, atau perangkat lain Anda oleh situs web,
          yang berisi perincian riwayat penjelajahan Anda di situs web tersebut
          di antara banyak kegunaannya. 4) Negara mengacu pada: Indonesia. 5)
          Perangkat berarti setiap perangkat yang dapat mengakses Layanan
          seperti komputer, ponsel, atau tablet digital. 6) Data Pribadi adalah
          setiap informasi yang berkaitan dengan individu yang teridentifikasi
          atau dapat diidentifikasi. 7) Layanan mengacu pada Aplikasi berbasis
          website. 8) Penyedia Layanan berarti setiap orang atau badan hukum
          yang memproses data atas nama Perusahaan. Ini mengacu pada perusahaan
          atau individu pihak ketiga yang dipekerjakan oleh Perusahaan untuk
          memfasilitasi Layanan, untuk menyediakan Layanan atas nama Perusahaan,
          untuk melakukan layanan terkait dengan Layanan Perusahaan atau untuk
          membantu Perusahaan dalam menganalisis bagaimana Layanan digunakan. 9)
          Data Penggunaan mengacu pada data yang dikumpulkan secara otomatis,
          baik yang dihasilkan oleh penggunaan Layanan atau dari infrastruktur
          Layanan itu sendiri (misalnya, durasi kunjungan halaman). 10) Aplikasi
          mengacu pada aplikasi Berbayar, yang dapat diakses di Apple App Store
          dan Google Play Store. Anda mengacu pada individu yang mengakses atau
          menggunakan Layanan, atau perusahaan atau badan hukum lainnya atas
          nama individu yang mengakses atau menggunakan Layanan, sebagaimana
          berlaku.
          <br></br>
          <br></br>
          <Box component="span" fontWeight="bold">
            Pengumpulan dan Penggunaan Data Pribadi Anda
          </Box>
          <br></br>
          <br></br>
          <Box component="span" fontWeight="bold">
            Data pribadi
          </Box>
          <br></br>
          <br></br>
          Saat menggunakan layanan kami, kami mungkin meminta Anda untuk
          memberikan kami informasi pengenal pribadi tertentu yang dapat
          digunakan untuk menghubungi atau mengidentifikasi Anda. Informasi yang
          dapat diidentifikasi secara pribadi mungkin termasuk, tetapi tidak
          terbatas pada:
          <br></br>
          <br></br>
          1) Nama lengkap. 2) Nomor telepon. 3) Nomor KTP. 4) Foto KTP & Foto
          Diri dengan KTP. 5) Alamat lengkap. 6) Nomor rekening bank atau dompet
          digital
          <br></br>
          <br></br>
          <Box component="span" fontWeight="bold">
            Data Penggunaan
          </Box>
          <br></br>
          <br></br>
          Data Penggunaan dikumpulkan secara otomatis saat menggunakan Layanan.
          <br></br>
          <br></br>
          Data Penggunaan dapat mencakup informasi seperti alamat Protokol
          Internet Perangkat Anda (misalnya alamat IP), jenis browser, versi
          browser, halaman Layanan kami yang Anda kunjungi, waktu dan tanggal
          kunjungan Anda, waktu yang dihabiskan untuk halaman tersebut,
          perangkat unik pengenal dan data diagnostik lainnya.
          <br></br>
          <br></br>
          Saat Anda mengakses Layanan oleh atau melalui perangkat seluler, kami
          dapat mengumpulkan informasi tertentu secara otomatis, termasuk, namun
          tidak terbatas pada, jenis perangkat seluler yang Anda gunakan, ID
          unik perangkat seluler Anda, alamat IP perangkat seluler Anda, operasi
          perangkat seluler Anda sistem, jenis peramban Internet seluler yang
          Anda gunakan, pengidentifikasi perangkat unik, dan data diagnostik
          lainnya.
          <br></br>
          <br></br>
          Kami juga dapat mengumpulkan informasi yang dikirimkan browser Anda
          setiap kali Anda mengunjungi Layanan kami atau saat Anda mengakses
          Layanan oleh atau melalui perangkat seluler.
          <br></br>
          <br></br>
          <Box component="span" fontWeight="bold">
            Teknologi Pelacakan dan Cookie
          </Box>
          <br></br>
          <br></br>
          Kami menggunakan Cookie dan teknologi pelacakan serupa untuk melacak
          aktivitas di Layanan Kami dan menyimpan informasi tertentu. Teknologi
          pelacakan yang digunakan adalah beacon, tag, dan skrip untuk
          mengumpulkan dan melacak informasi serta untuk meningkatkan dan
          menganalisis Layanan kami. Teknologi yang kami gunakan mungkin
          termasuk:
          <br></br>
          <br></br>
          Cookie atau Cookie Peramban. Cookie adalah file kecil yang ditempatkan
          di Perangkat Anda. Anda dapat menginstruksikan browser Anda untuk
          menolak semua Cookie atau untuk menunjukkan saat Cookie sedang
          dikirim. Namun, jika Anda tidak menerima Cookie, Anda mungkin tidak
          dapat menggunakan beberapa bagian dari Layanan kami. Kecuali Anda
          telah menyesuaikan pengaturan browser Anda sehingga akan menolak
          Cookies, Layanan kami dapat menggunakan Cookies. Cookie dapat berupa
          Cookie “Persisten” atau “Sesi”. Persistent Cookies akan tetap ada di
          komputer pribadi atau perangkat seluler Anda saat Anda offline,
          sedangkan Session Cookies akan dihapus segera setelah Anda menutup
          browser web Anda.
          <br></br>
          <br></br>
          Kami menggunakan Cookie Sesi dan Persisten untuk tujuan yang
          ditetapkan di bawah ini:
          <br></br>
          <br></br>
          Jenis Cookie Penting/Diperlukan: Cookie Sesi Dikelola oleh: Kami
          Tujuan: Cookie ini penting untuk memberi Anda layanan yang tersedia
          melalui Aplikasi dan untuk memungkinkan Anda menggunakan beberapa
          fiturnya. Mereka membantu mengautentikasi pengguna dan mencegah
          penggunaan akun pengguna secara curang. Tanpa Cookie ini, layanan yang
          Anda minta tidak dapat disediakan, dan Kami hanya menggunakan Cookie
          ini untuk menyediakan layanan ini kepada Anda.
          <br></br>
          <br></br>
          Kebijakan Cookie / Pemberitahuan Penerimaan Jenis Cookie: Cookie Tetap
          Dikelola oleh: Kami Tujuan: Cookie ini mengidentifikasi apakah
          pengguna telah menerima penggunaan cookie pada Aplikasi.
          <br></br>
          <br></br>
          Jenis Cookie Fungsionalitas: Cookie Persisten Dikelola oleh: Kami
          Tujuan: Cookie ini memungkinkan kami untuk mengingat pilihan yang Anda
          buat saat menggunakan Aplikasi, seperti mengingat detail login Anda
          atau preferensi bahasa. Tujuan dari Cookie ini adalah untuk memberi
          Anda pengalaman yang lebih dipersonalisasi dan untuk menghindari Anda
          harus memasukkan kembali preferensi Anda setiap kali Anda menggunakan
          Aplikasi.
          <br></br>
          <br></br>
          <Box component="span" fontWeight="bold">
            Penggunaan Data Pribadi Anda
          </Box>
          <br></br>
          <br></br>
          Perusahaan dapat menggunakan Data Pribadi untuk tujuan berikut:
          <br></br>
          <br></br>
          1) Untuk menyediakan dan memelihara Layanan kami, termasuk untuk
          memantau penggunaan Layanan kami. 2) Untuk mengelola Akun Anda, untuk
          mengelola pendaftaran Anda sebagai pengguna Layanan. Data Pribadi yang
          Anda berikan dapat memberi Anda akses ke berbagai fungsi Layanan yang
          tersedia untuk Anda sebagai pengguna terdaftar. 3) Untuk pelaksanaan
          kontrak, pengembangan, kepatuhan, dan pelaksanaan kontrak pembelian
          untuk produk, barang, atau layanan yang telah Anda beli atau kontrak
          lain dengan kami melalui Layanan. 4) Untuk menghubungi Anda, Untuk
          menghubungi Anda melalui panggilan telepon, SMS, atau bentuk
          komunikasi elektronik lainnya yang setara, seperti pemberitahuan push
          aplikasi seluler terkait pembaruan atau komunikasi informatif terkait
          fungsi, produk, atau layanan yang dikontrak, termasuk pembaruan
          keamanan, jika perlu atau wajar untuk implementasinya. 5) Untuk
          memberi Anda berita, penawaran khusus, dan informasi umum tentang
          barang, layanan, dan acara lain yang kami tawarkan yang serupa dengan
          yang telah Anda beli atau tanyakan kecuali Anda memilih untuk tidak
          menerima informasi tersebut. 6) Untuk mengelola permintaan Anda, Untuk
          menghadiri dan mengelola permintaan Anda kepada Kami. 7) Untuk
          transfer bisnis, kami dapat menggunakan informasi Anda untuk
          mengevaluasi atau melakukan merger, divestasi, restrukturisasi,
          reorganisasi, pembubaran atau penjualan atau pengalihan sebagian atau
          seluruh aset kami, baik sebagai kelangsungan usaha atau sebagai bagian
          dari kebangkrutan, likuidasi, atau proses serupa, di mana Data Pribadi
          yang Kami miliki tentang pengguna Layanan kami termasuk di antara aset
          yang ditransfer. 8) Untuk tujuan lain, Kami dapat menggunakan
          informasi Anda untuk tujuan lain, seperti analisis data,
          mengidentifikasi tren penggunaan, menentukan keefektifan kampanye
          promosi kami dan untuk mengevaluasi dan meningkatkan Layanan, produk,
          layanan, pemasaran, dan pengalaman Anda. Kami dapat memberikan
          informasi pribadi Anda dalam situasi berikut:
          <br></br>
          <br></br>
          1) Dengan Penyedia Layanan, Kami dapat memberikan informasi pribadi
          Anda dengan Penyedia Layanan untuk mendukung, memantau, dan
          menganalisis penggunaan Layanan kami dan untuk menghubungi Anda. 2)
          Untuk transfer bisnis, kami dapat membagikan atau mentransfer
          informasi pribadi Anda sehubungan dengan, atau selama negosiasi untuk,
          merger, penjualan aset Perusahaan, pembiayaan, atau akuisisi semua
          atau sebagian bisnis kami ke perusahaan lain. 3) Dengan Afiliasi, Kami
          dapat membagikan informasi Anda dengan afiliasi Kami, dalam hal ini
          kami akan meminta afiliasi tersebut untuk mematuhi Kebijakan Privasi
          ini. Afiliasi termasuk perusahaan induk kami dan anak perusahaan
          lainnya, mitra usaha patungan atau perusahaan lain yang kami
          kendalikan atau berada di bawah kendali bersama kami. 4) Dengan mitra
          bisnis, kami dapat membagikan informasi Anda dengan mitra bisnis kami
          untuk menawarkan produk, layanan, atau promosi tertentu kepada Anda.
          5) Dengan pengguna lain, saat Anda berbagi informasi pribadi atau
          berinteraksi di area publik dengan pengguna lain, informasi tersebut
          dapat dilihat oleh semua pengguna dan dapat didistribusikan secara
          publik di luar. 6) Dengan persetujuan Anda, kami dapat mengungkapkan
          informasi pribadi Anda untuk tujuan lain dengan persetujuan Anda.
          <br></br>
          <br></br>
          <Box component="span" fontWeight="bold">
            Informasi Pembayaran
          </Box>
          <br></br>
          <br></br>
          Untuk memulai dan memproses tagihan yang Anda buat atau bayar, kami
          bekerja sama dengan Xendit, penyedia gateway pembayaran, yang akan
          mengumpulkan informasi pembayaran Anda sesuai dengan metode yang Anda
          pilih. Semua data Anda akan dienkripsi melalui Standar Keamanan Data
          Industri Kartu Pembayaran (PCI-DSS), dan terdaftar, diawasi &
          terlisensi oleh Bank Indonesia.
          <br></br>
          <br></br>
          Untuk pembayaran (disbursement) kami bekerja sama dengan Flip for
          Business, suatu Perseroan Terbatas yang menciptakan, menyediakan,
          mengelola, dan mengembangkan Platform Flip For Business yang berfungsi
          untuk menyediakan Layanan Flip For Business berupa transfer dana antar
          rekening bank, yang mana Flip telah memperoleh izin dari Bank
          Indonesia sebagai penyelenggara transfer dana No. 18/196/DKSP/68
          tanggal 4 Oktober 2016 dan telah mendapat konversi izin sebagai
          Penyedia Jasa Pembayaran – Kategori Izin 3 melalui Surat No.
          23/1363/Jkt/Srt/B mengenai Konversi Izin Penyelenggara Jasa Sistem
          Pembayaran Paska Pemberlakuan Peraturan Bank Indonesia No.
          22/23/PBI/2020 tentang Sistem Pembayaran yang diterbitkan pada tanggal
          29 Desember 2021 oleh Bank Indonesia.
          <br></br>
          <br></br>
          <Box component="span" fontWeight="bold">
            Penyimpanan Data Pribadi Anda
          </Box>
          <br></br>
          <br></br>
          Perusahaan akan menyimpan Data Pribadi Anda hanya selama diperlukan
          untuk tujuan yang ditetapkan dalam Kebijakan Privasi ini. Kami akan
          menyimpan dan menggunakan Data Pribadi Anda sejauh yang diperlukan
          untuk mematuhi kewajiban hukum kami (misalnya, jika kami diharuskan
          menyimpan data Anda untuk mematuhi undang-undang yang berlaku),
          menyelesaikan perselisihan, dan menegakkan perjanjian dan kebijakan
          hukum kami.
          <br></br>
          <br></br>
          Perusahaan juga akan menyimpan Data Penggunaan untuk keperluan
          analisis internal. Data Penggunaan umumnya disimpan untuk jangka waktu
          yang lebih singkat, kecuali data ini digunakan untuk memperkuat
          keamanan atau untuk meningkatkan fungsionalitas Layanan Kami, atau
          Kami secara hukum berkewajiban untuk menyimpan data ini untuk jangka
          waktu yang lebih lama.
          <br></br>
          <br></br>
          <Box component="span" fontWeight="bold">
            Transfer Data Pribadi Anda
          </Box>
          <br></br>
          <br></br>
          Informasi Anda, termasuk Data Pribadi, diproses di kantor operasional
          Perusahaan dan tempat lain di mana para pihak yang terlibat dalam
          pemrosesan berada. Artinya, informasi ini dapat ditransfer ke — dan
          disimpan di — komputer yang terletak di luar negara bagian, provinsi,
          negara, atau yurisdiksi pemerintah Anda lainnya di mana undang-undang
          perlindungan data mungkin berbeda dari yurisdiksi Anda.
          <br></br>
          <br></br>
          Persetujuan Anda terhadap Kebijakan Privasi ini yang diikuti dengan
          pengiriman informasi tersebut oleh Anda merupakan persetujuan Anda
          untuk pengalihan tersebut.
          <br></br>
          <br></br>
          Perusahaan akan mengambil semua langkah yang diperlukan secara wajar
          untuk memastikan bahwa data Anda diperlakukan dengan aman dan sesuai
          dengan Kebijakan Privasi ini dan tidak ada transfer Data Pribadi Anda
          yang akan dilakukan ke organisasi atau negara kecuali ada kontrol yang
          memadai termasuk keamanan data Anda. Data dan informasi pribadi
          lainnya.
          <br></br>
          <br></br>
          <Box component="span" fontWeight="bold">
            Pengungkapan Data Pribadi Anda
          </Box>
          <br></br>
          <br></br>
          <Box component="span" fontWeight="bold">
            Transaksi bisnis
          </Box>
          <br></br>
          <br></br>
          Jika Perusahaan terlibat dalam merger, akuisisi, atau penjualan aset,
          Data Pribadi Anda dapat dialihkan. Kami akan memberikan pemberitahuan
          sebelum Data Pribadi Anda dialihkan dan tunduk pada Kebijakan Privasi
          yang berbeda.
          <br></br>
          <br></br>
          <Box component="span" fontWeight="bold">
            Penegakan hukum
          </Box>
          <br></br>
          <br></br>
          Dalam keadaan tertentu, Perusahaan mungkin diharuskan untuk
          mengungkapkan Data Pribadi Anda jika diwajibkan oleh undang-undang
          atau sebagai tanggapan atas permintaan yang sah oleh otoritas publik
          (misalnya pengadilan atau lembaga pemerintah).
          <br></br>
          <br></br>
          <Box component="span" fontWeight="bold">
            Persyaratan Hukum Lainnya
          </Box>
          <br></br>
          <br></br>
          Perusahaan dapat mengungkapkan Data Pribadi Anda dengan itikad baik
          dan meyakini bahwa tindakan tersebut diperlukan untuk:
          <br></br>
          <br></br>
          1) Mematuhi kewajiban hukum. 2) Melindungi dan membela hak atau
          properti Perusahaan. 3) Cegah atau selidiki kemungkinan kesalahan
          sehubungan dengan Layanan. 4) Melindungi keamanan pribadi Pengguna
          Jasa atau masyarakat. 5) Melindungi dari tanggung jawab hukum. 6)
          Keamanan Data Pribadi Anda
          <br></br>
          <br></br>
          Keamanan Data Pribadi Anda penting bagi kami, namun ingat bahwa tidak
          ada metode transmisi melalui Internet, atau metode penyimpanan
          elektronik yang 100% aman. Meskipun Kami berusaha untuk menggunakan
          cara yang dapat diterima secara komersial untuk melindungi Data
          Pribadi Anda, Kami tidak dapat menjamin keamanan mutlaknya.
          <br></br>
          <br></br>
          <Box component="span" fontWeight="bold">
            Tautan ke Situs Web Lain
          </Box>
          <br></br>
          <br></br>
          Layanan kami mungkin berisi tautan ke situs web lain yang tidak
          dioperasikan oleh kami. Jika Anda mengklik tautan pihak ketiga, Anda
          akan dialihkan ke situs pihak ketiga tersebut. Kami sangat
          menganjurkan Anda untuk meninjau Kebijakan Privasi dari setiap situs
          yang Anda kunjungi.
          <br></br>
          <br></br>
          Kami tidak memiliki kendali atas dan tidak bertanggung jawab atas
          konten, kebijakan privasi, atau praktik situs atau layanan pihak
          ketiga mana pun.
          <br></br>
          <br></br>
          <Box component="span" fontWeight="bold">
            Perubahan pada Kebijakan Privasi ini
          </Box>
          <br></br>
          <br></br>
          Kami dapat memperbarui Kebijakan Privasi Kami dari waktu ke waktu.
          Kami akan memberitahu Anda tentang setiap perubahan dengan memperbarui
          Kebijakan Privasi di halaman ini.
          <br></br>
          <br></br>
          Kami akan memberi tahu Anda melalui email dan/atau pemberitahuan di
          Layanan Kami, sebelum perubahan berlaku dan memperbarui tanggal
          “Terakhir diperbarui” di bagian atas Kebijakan Privasi ini.
          <br></br>
          <br></br>
          Anda disarankan untuk meninjau Kebijakan Privasi ini secara berkala
          untuk setiap perubahan. Perubahan pada Kebijakan Privasi ini efektif
          saat di posting di halaman ini.
          <br></br>
          <br></br>
          <Box component="span" fontWeight="bold">
            Hubungi kami
          </Box>
          <br></br>
          <br></br>
          Jika Anda memiliki pertanyaan tentang Kebijakan Privasi ini, Anda
          dapat menghubungi kami melalui email: info@mudahindonesia.com
          <br></br>
          <br></br>
          Diperbaharui pada tanggal: 5 Februari 2023
        </Typography>
      </Paper>
    </Modal>
  );
};

export default TncSk;
