import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const appThemeBase = createTheme({
  typography: {
    fontFamily: "Inter, Arial",
    allVariants: {
      color: "#111111",
    },

    h1: { fontSize: 32 },
    h2: { fontSize: 26 },
    h3: { fontSize: 22 },
    h4: { fontSize: 18 },
    body1: {
      fontSize: 15,
    },
    body2: {
      fontSize: 12,
    },
    subtitle1: {
      fontSize: 20,
    },

    button: {
      textTransform: "none",
      fontSize: 16,
    },
  },

  palette: {
    primary: {
      light: "#3477d4",
      main: "#0049ad",
      mudahBlack: "#111111",
      mudahWhite: "#fafafa",
      mudahOffWhite: "#f3f5f6",
      mudahLightGrey: "#a9a9a9",
      mudahDarkGrey: "#787878",
      mudahGreen: "#4cd964",
      mudahRed: "#ff3b30",
    },
  },
});

export const appTheme = responsiveFontSizes(appThemeBase, {
  breakpoints: ["xs", "sm", "md", "lg", "xl"],
  factor: 3,
});
