import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { db } from '../../../firebase';

// import mui components
import {
  Button,
  Box,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

// import auth components
import { useUserAuth } from '../../../context/UserAuthContext';

// import bank data
import bankList from '../../../data/bankList';

// import backend api
import API from '../../../api';

function createBankData(code, name) {
  return {
    code,
    name,
  };
}

const bankNames = Object.keys(bankList.bank).map((key) =>
  createBankData(key, bankList.bank[key])
);

const walletNames = Object.keys(bankList.ewallet).map((key) =>
  createBankData(key, bankList.ewallet[key])
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const KYCBank = ({
  handleNext,
  name,
  phoneNumber,
  idNumber,
  idImageURL,
  selfieImageURL,
  address,
  settlementBank,
  updateKycData,
}) => {
  const [bankNameSearch, setBankNameSearch] = useState('');
  const [settlementBankProp, setSettlementBankProp] = useState({
    ...settlementBank,
  });

  const { user, updateUserProfile } = useUserAuth();

  const [kycSubmitLoading, setKycSubmitLoading] = useState(false);
  const [selectBankModalOpen, setSelectBankModalOpen] = useState(false);

  const handleSelectBankModalOpen = () => {
    setSelectBankModalOpen(true);
  };

  const handleSelectBankModalClose = () => {
    setSelectBankModalOpen(false);
  };

  // Tab Change
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const formHandler = async (e) => {
    e.preventDefault();

    setKycSubmitLoading(true);

    if (
      settlementBankProp.accountName === '' ||
      settlementBankProp.accountNo === '' ||
      settlementBankProp.bankName === '' ||
      settlementBankProp.bankCode === '' ||
      settlementBankProp.bankType === ''
    ) {
      setKycSubmitLoading(false);
      alert('Mohon isi semua data yang diperlukan');
      return;
    }

    try {
      updateKycData({
        settlementBank: settlementBankProp,
      });
      await addDoc(collection(db, 'settlement_accounts'), {
        ...settlementBankProp,
        userRef: doc(db, 'users', user.uid),
      });

      // add to firebase
      await addDoc(collection(db, 'kyc_requests'), {
        name: name,
        phoneNumber: phoneNumber,
        idnumber: idNumber,
        idImageURL: idImageURL,
        selfieImageURL: selfieImageURL,
        address: address,
        settlementBank: settlementBankProp,
        userRef: doc(db, 'users', user.uid),
        createdAt: Timestamp.fromDate(new Date()),
      });
      console.log('KYC requests successfully written!');

      await updateUserProfile({
        verified: 'pending',
      });

      API.post(
        '/merchants/kyc',
        {
          name: name,
          personal_id_number: idNumber,
          personal_id_image: idImageURL,
          selfie_image: selfieImageURL,
          street: address.fullAddress,
          district: address.district,
          city: address.city,
          province: address.province,
          postcode: address.postalCode,
          settlement_name: settlementBankProp.accountName,
          account_number: settlementBankProp.accountNo,
          bank_type: settlementBankProp.bankType.toUpperCase(),
          bank_name: settlementBankProp.bankName,
          bank_code: settlementBankProp.bankCode,
        },
        {
          timeout: 7000,
          headers: {
            'Firebase-Token': await user.getIdToken(),
          },
        }
      )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });

      setKycSubmitLoading(false);
      handleNext();
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <>
      <Box component="form" onSubmit={formHandler}>
        <Grid container justifyContent="center" sx={{ width: 1 }}>
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sx={{ width: 1, mt: 4 }}
          >
            <TextField
              required
              id="bank-name"
              name="bank name"
              label="Bank / e-wallet Penarikan"
              placeholder="Contoh: Bank Central Asia"
              fullWidth
              value={settlementBankProp.bankName}
              onClick={handleSelectBankModalOpen}
            />
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sx={{ width: 1, mt: 2 }}
          >
            <TextField
              required
              id="account-name"
              name="account name"
              label="Nama Akun"
              placeholder="Contoh: Ali Akbar"
              fullWidth
              value={settlementBankProp.accountName}
              onChange={(e) =>
                setSettlementBankProp({
                  ...settlementBankProp,
                  accountName: e.target.value,
                })
              }
            />
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sx={{ width: 1, mt: 2 }}
          >
            <TextField
              required
              id="account-no"
              name="account number"
              label="Nomor Rekening/HP"
              placeholder="Contoh: 343212567"
              fullWidth
              value={settlementBankProp.accountNo}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(e) =>
                setSettlementBankProp({
                  ...settlementBankProp,
                  accountNo: e.target.value,
                })
              }
            />
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sx={{ width: 1, mt: 4 }}
          >
            {kycSubmitLoading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                fullWidth
                type="submit"
                sx={{ height: '50px', borderRadius: '25px' }}
                disabled={
                  settlementBankProp.bankName === '' ||
                  settlementBankProp.accountName === '' ||
                  settlementBankProp.accountNo === ''
                }
              >
                <Typography sx={{ color: 'primary.mudahWhite' }}>
                  Selanjutnya
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>

      {/* Modal - Select Bank Account */}
      <Modal open={selectBankModalOpen} onClose={handleSelectBankModalClose}>
        <Paper
          sx={{
            mx: 'auto',
            mt: '10vh',
            p: 4,
            width: { xs: '80vw', md: 400 },
            backgroundColor: 'primary.mudahWhite',
          }}
        >
          <TextField
            required
            id="bank-name"
            name="bank name"
            label="Cari Nama Bank / E-wallet"
            placeholder="Contoh: Mandiri"
            fullWidth
            sx={{
              mt: 2,
            }}
            value={bankNameSearch}
            onChange={(e) => setBankNameSearch(e.target.value)}
          />

          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                variant="fullWidth"
              >
                <Tab label="Bank" />
                <Tab label="E-wallet" />
              </Tabs>
            </Box>
            <TabPanel value={selectedTab} index={0}>
              <Box sx={{ maxHeight: '40vh', overflow: 'auto' }}>
                <List sx={{ p: 0 }}>
                  {bankNames
                    .filter((pair) =>
                      pair.name
                        .toLowerCase()
                        .includes(bankNameSearch.toLowerCase())
                    )
                    .map((n) => (
                      <ListItem disablePadding key={n.code}>
                        <ListItemButton
                          onClick={() => {
                            setSettlementBankProp({
                              ...settlementBankProp,
                              bankCode: n.code,
                              bankName: n.name,
                              bankType: 'bank',
                            });
                            setSelectBankModalOpen(false);
                          }}
                        >
                          <ListItemText primary={n.name} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                </List>
              </Box>
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <Box sx={{ maxHeight: '40vh', overflow: 'auto' }}>
                <List sx={{ p: 0 }}>
                  {walletNames
                    .filter((pair) => pair.name.includes(bankNameSearch))
                    .map((n) => (
                      <ListItem disablePadding key={n.code}>
                        <ListItemButton
                          onClick={() => {
                            setSettlementBankProp({
                              ...settlementBankProp,
                              bankCode: n.code,
                              bankName: n.name,
                              bankType: 'ewallet',
                            });
                            handleSelectBankModalClose();
                          }}
                        >
                          <ListItemText primary={n.name} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                </List>
              </Box>
            </TabPanel>
          </Box>

          <Grid container spacing={2} sx={{ mt: 4 }}>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleSelectBankModalClose}
                sx={{
                  color: 'primary.mudahRed',
                  borderColor: 'primary.mudahRed',
                }}
              >
                Batal
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
};

export default KYCBank;
