// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { initializeFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAeRx0D7LTGzjJjHLiCY8DMACBaO3JE8Lo',
  authDomain: 'mudah-v2.firebaseapp.com',
  projectId: 'mudah-v2',
  storageBucket: 'mudah-v2.appspot.com',
  messagingSenderId: '51048104010',
  appId: '1:51048104010:web:22a25d918e8ca993ae66b3',
  measurementId: 'G-L40D3HT29F',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;

export const auth = getAuth(app);
auth.languageCode = 'id';

export const db = initializeFirestore(app, {
  useFetchStreams: false,
});
export const storage = getStorage(app);
