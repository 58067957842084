import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';

// import ui components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import SVGIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

// import page components
import RegisterForm from '../components/register/RegisterForm';
import RegisterOTP from '../components/register/RegisterOTP';
import RegisterTnc from '../components/register/RegisterTnc';
import RegisterTnc2 from '../components/register/RegisterTnc2';
import RegisterPin from '../components/register/RegisterPin';
import RegisterSuccess from '../components/register/RegisterSuccess';
import MudahCopyright from '../components/MudahCopyright';
import MudahLogoPair from '../components/MudahLogoPair';

// import static assets
import BgRight from '../../assets/bg-right.png';
import XenditLogo from '../../assets/xendit-logo.png';

const steps = [
  'Buat akun baru',
  'Verifikasi OTP',
  'Syarat dan Ketentuan',
  'Atur PIN',
  'Berhasil',
];

function getStepContent(step, handleNext, formData, updateFormData) {
  switch (step) {
    case 0:
      return (
        <RegisterForm
          handleNext={handleNext}
          {...formData}
          updateFormData={updateFormData}
        />
      );
    case 1:
      return (
        <RegisterOTP
          handleNext={handleNext}
          {...formData}
          updateFormData={updateFormData}
        />
      );
    case 2:
      return <RegisterTnc2 handleNext={handleNext} {...formData} />;
    case 3:
      return <RegisterPin handleNext={handleNext} />;
    case 4:
      return <RegisterSuccess />;
    default:
      throw new Error('Unknown step');
  }
}

const INITIAL_FORM_DATA = {
  bizName: '',
  phoneNumber: '',
  bizType: '',
  phoneWithCountryCode: '',
};

const Register = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const updateFormData = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        backgroundImage: `url(${BgRight})`,
        backgroundPosition: 'bottom 0 right 0',
        backgroundSize: '40%',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Grid
        container
        sx={{ mt: { xs: 5, md: 10 }, mx: 'auto', width: { xs: 1, md: '90%' } }}
      >
        <Hidden mdDown>
          <Grid
            item
            xs={12}
            sx={{
              px: { xs: 5, md: 7 },
            }}
          >
            <MudahLogoPair />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              alignItems: 'center',
              height: '80vh',
            }}
          >
            <Grid
              container
              justifyContent="flex-end"
              sx={{
                my: 'auto',
                p: { xs: 3, md: 5 },
                height: 1,
              }}
            >
              <Grid item xs={1}>
                <SVGIcon sx={{ mt: '3px' }}>
                  <FaCheckCircle color="#0049ad" />
                </SVGIcon>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h3" fontWeight="bold">
                  Daftar simpel & KYC 5 menit
                </Typography>
                <Typography variant="subtitle1">
                  Terima 100+ metode transaksi bank non-tunai dan dompet
                  digital.
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <SVGIcon sx={{ mt: '3px' }}>
                  <FaCheckCircle color="#0049ad" />
                </SVGIcon>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h3" fontWeight="bold">
                  Saldo QRIS cairnya instan
                </Typography>
                <Typography variant="subtitle1">
                  Apapun usaha yang kamu jalanin, kami bantu kamu dapetin QRIS.
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <SVGIcon sx={{ mt: '3px' }}>
                  <FaCheckCircle color="#0049ad" />
                </SVGIcon>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h3" fontWeight="bold">
                  Kelola dengan dasbor Mudah gratis
                </Typography>
                <Typography variant="subtitle1">
                  Tanpa ada biaya bulanan, hanya bayar tiap transaksi yang
                  berhasil.
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={11} container alignItems="center">
                <Grid item>
                  <Typography variant="h3" fontWeight="bold" sx={{ mr: 2 }}>
                    Bekerja sama dengan:{' '}
                  </Typography>
                </Grid>
                <Grid item>
                  <img src={XenditLogo} alt="Xendit Logo" />
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mt: 4 }}>
                <MudahCopyright />
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          md={6}
          container
          justifyContent="center"
          sx={{ alignItems: 'center', px: { xs: 3, md: 5 } }}
        >
          <Grid item container sx={12} justifyContent="center">
            <Paper
              elevation={4}
              sx={{
                p: { xs: 3, md: 5 },
                height: 1,
              }}
            >
              <Hidden mdUp>
                <Box sx={{ mb: 3 }}>
                  <MudahLogoPair />
                </Box>
              </Hidden>
              {activeStep === steps.length ? (
                <>
                  <Navigate to="/" />
                </>
              ) : (
                <>
                  <Stepper activeStep={activeStep} sx={{ px: 0 }}>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel></StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <Typography variant="h4" sx={{ mt: 2 }}>
                    {activeStep + 1} dari 5 —{' '}
                    <Box component="span" fontWeight="bold">
                      {steps[activeStep]}
                    </Box>
                  </Typography>
                  {getStepContent(
                    activeStep,
                    handleNext,
                    formData,
                    updateFormData
                  )}
                </>
              )}
              <Typography variant="body2" sx={{ mt: 4, textAlign: 'center' }}>
                🔒 Semua info kamu dijamin rahasia dan tidak disalahgunakan
              </Typography>
            </Paper>
          </Grid>

          <Hidden mdUp>
            <Grid item container justifyContent="center" sx={12}>
              <MudahCopyright />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <div id="sign-up-button"></div>
    </Box>
  );
};

export default Register;
