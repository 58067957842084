import React, { useEffect, useState } from 'react';
import Header from '../../layouts/Header';
import Sidebar from '../../layouts/Sidebar';

// import auth components
import { useUserAuth } from '../../context/UserAuthContext';

// import static assets
import '../css/MainContainer.css';

// import components
import MudahCopyright from '../components/MudahCopyright';
import InputPin from '../components/InputPin';

// import mui components
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// import helper functions
import { cyrb53 } from '../../helper';

const INITIAL_PIN_DATA = {
  pinNumber: ['', '', ''],
  pinValid: [false, false, false],
  newPinConfirmed: false,
};

const Profile = () => {
  const [profileData, setProfileData] = useState(null);
  const [pinChangeModalOpen, setPinChangeModalOpen] = useState(false);
  const [pinData, setPinData] = useState(INITIAL_PIN_DATA);
  const [submitChange, setSubmitChange] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePinChangeModalClose = () => setPinChangeModalOpen(false);

  const { userData, refreshUserData, updateUserProfileFunc } = useUserAuth();

  const pinReg = new RegExp('^[0-9]{6}$');
  const pinChangeHandler0 = (inputNumber) => {
    if (inputNumber === undefined) {
      inputNumber = '';
    }

    var pinArray = pinData.pinNumber;
    var pinValidArray = pinData.pinValid;
    pinArray[0] = inputNumber;

    if (pinReg.test(inputNumber)) {
      pinValidArray[0] = true;
    } else {
      pinValidArray[0] = false;
    }

    setPinData({
      pinNumber: pinArray,
      pinValid: pinValidArray,
      newPinConfirmed: pinData.newPinConfirmed,
    });

    checkPinsValid();
  };
  const pinChangeHandler1 = (inputNumber) => {
    if (inputNumber === undefined) {
      inputNumber = '';
    }

    var pinArray = pinData.pinNumber;
    var pinValidArray = pinData.pinValid;
    pinArray[1] = inputNumber;

    if (pinReg.test(inputNumber)) {
      pinValidArray[1] = true;
    } else {
      pinValidArray[1] = false;
    }

    setPinData({
      pinNumber: pinArray,
      pinValid: pinValidArray,
      newPinConfirmed: false,
    });

    checkPinsValid();
  };
  const pinChangeHandler2 = (inputNumber) => {
    if (inputNumber === undefined) {
      inputNumber = '';
    }

    var pinArray = pinData.pinNumber;
    var pinValidArray = pinData.pinValid;
    pinArray[2] = inputNumber;

    if (pinReg.test(inputNumber)) {
      pinValidArray[2] = true;
    } else {
      pinValidArray[2] = false;
    }

    setPinData({
      pinNumber: pinArray,
      pinValid: pinValidArray,
      newPinConfirmed: false,
    });

    if (pinArray[1] === pinArray[2]) {
      setPinData({
        ...pinData,
        newPinConfirmed: true,
      });
    }

    checkPinsValid();
  };

  const checkPinsValid = () => {
    let pinValid = true;
    pinData.pinValid.forEach((pin) => {
      pinValid = pinValid && pin;
    });
    pinValid = pinValid && pinData.newPinConfirmed;
    setSubmitChange(pinValid);
  };

  const formHandler = async (e) => {
    e.preventDefault();

    setLoading(true);

    let hashedPin = cyrb53(pinData.pinNumber[0]);
    try {
      const profileData = await userData();
      if (hashedPin === profileData.pinNumber) {
        let hashedNewPin = cyrb53(pinData.pinNumber[1]);

        await updateUserProfileFunc({ pinNumber: hashedNewPin });

        await refreshUserData();

        setLoading(false);
        handlePinChangeModalClose();
        alert('PIN berhasil diganti');

        window.location.reload();
      } else {
        setLoading(false);
        alert('PIN gagal diganti. Pastikan PIN lama benar dan PIN baru sama');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getProfileData = async () => {
      await userData().then((data) => {
        setProfileData(data);
      });
    };
    getProfileData();
  }, [userData]);

  return (
    <>
      <Sidebar />
      <div className="MainContainer">
        <Header pageTitle="Profil" />
        <Paper elevation={4} sx={{ mt: '30px', p: '35px' }}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              {profileData && (
                <Card
                  variant="outlined"
                  sx={{ mt: 2, p: 2, mx: 'auto', maxWidth: 600 }}
                >
                  {/* <Typography variant="h2" fontWeight="bold">
                    Informasi Pemilik
                  </Typography>
                  <Typography variant="h3" sx={{ mt: 2 }}>
                    Nama Pemilik
                  </Typography>
                  <Typography>{profileData.owner.name}</Typography>
                  <Typography variant="h3" sx={{ mt: 1 }}>
                    Nomor Telepon
                  </Typography>
                  <Typography>{profileData.phoneNumber}</Typography> */}

                  {/* <Divider sx={{ mt: 2 }} /> */}

                  <Typography variant="h2" fontWeight="bold">
                    Informasi Usaha
                  </Typography>

                  <Typography variant="h3" sx={{ mt: 2 }}>
                    Nama Usaha
                  </Typography>
                  <Typography>{profileData.businessName}</Typography>

                  {profileData.businessType && (
                    <>
                      <Typography variant="h3" sx={{ mt: 1 }}>
                        Jenis Usaha
                      </Typography>
                      <Typography>{profileData.businessType}</Typography>
                    </>
                  )}

                  <Typography variant="h3" sx={{ mt: 1 }}>
                    Nomor Telepon
                  </Typography>
                  <Typography>{profileData.phoneNumber}</Typography>

                  {/* <Typography variant="h5" fontWeight="bold" sx={{ mt: 2 }}>
                    Alamat Usaha
                  </Typography>
                  <Typography variant="h3" sx={{ mt: 2 }}>
                    Alamat Lengkap
                  </Typography>
                  <Typography>{profileData.address.fullAddress}</Typography>
                  <Typography variant="h3" sx={{ mt: 1 }}>
                    Kecamatan
                  </Typography>
                  <Typography>{profileData.address.district}</Typography>
                  <Typography variant="h3" sx={{ mt: 1 }}>
                    Kota/Kabupaten
                  </Typography>
                  <Typography>{profileData.address.city}</Typography>
                  <Typography variant="h3" sx={{ mt: 1 }}>
                    Provinsi
                  </Typography>
                  <Typography>{profileData.address.province}</Typography>
                  <Typography variant="h3" sx={{ mt: 1 }}>
                    Kode Pos
                  </Typography>
                  <Typography>{profileData.address.postcode}</Typography> */}
                </Card>
              )}

              <Card
                variant="outlined"
                sx={{ mt: 2, p: 2, mx: 'auto', maxWidth: 600 }}
              >
                <Button
                  variant="contained"
                  sx={{ backgroundColor: 'primary.mudahRed', borderRadius: 10 }}
                  onClick={() => setPinChangeModalOpen(true)}
                >
                  Ganti PIN
                </Button>
              </Card>
            </Grid>
          </Grid>
        </Paper>
        <MudahCopyright />
      </div>

      {/* Modal - Ganti PIN */}
      <Modal open={pinChangeModalOpen} onClose={handlePinChangeModalClose}>
        <Paper
          sx={{
            mx: 'auto',
            mt: '10vh',
            p: 4,
            width: { xs: '80vw', md: 400 },
            backgroundColor: 'primary.mudahWhite',
          }}
        >
          <Stack justifyContent="flex-end">
            <IconButton
              aria-label="close"
              sx={{ ml: 'auto' }}
              onClick={handlePinChangeModalClose}
            >
              <CloseIcon />
            </IconButton>
          </Stack>

          <Grid container justifyContent="center" spacing={2}>
            <Typography variant="h6" component="h2" fontWeight="bold">
              Ganti PIN
            </Typography>

            {/* original */}
            <Grid item xs={12}>
              <Typography fontWeight="bold">Pin Lama</Typography>
              <InputPin pinChangeHandler={pinChangeHandler0} />
            </Grid>

            {/* New */}
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography fontWeight="bold">Pin Baru</Typography>
              <InputPin pinChangeHandler={pinChangeHandler1} />
            </Grid>

            {/* Confirm */}
            <Grid item xs={12}>
              <Typography fontWeight="bold"> Konfirmasi PIN Baru</Typography>
              <InputPin pinChangeHandler={pinChangeHandler2} />
            </Grid>

            <Grid item container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    color: 'primary.mudahRed',
                    borderColor: 'primary.mudahRed',
                  }}
                  onClick={handlePinChangeModalClose}
                >
                  Batal
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!submitChange || loading}
                  onClick={formHandler}
                >
                  Ganti PIN
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: 'primary.mudahWhite',
                        position: 'absolute',
                      }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
};

export default Profile;
