import React from "react";

// import mui components
import { Box, Grid, TextField } from "@mui/material";

function PinInput({ children, onChange }) {
  const [inputRefs, setInputRefs] = React.useState([]);
  const [inputValues, setInputValues] = React.useState(
    Array(children.length).fill("")
  );

  const setValue = React.useCallback((newValue, index) => {
    setInputValues((prevValues) =>
      prevValues.map((prevValue, i) => (i === index ? newValue : prevValue))
    );
  }, []);

  const handleChange = (e) => {
    const eventValue = e.target.value;
    const currentInputIndex = inputRefs.indexOf(e.target);
    const inputValue = inputValues[currentInputIndex];

    if (eventValue.length === 0) {
      setValue(eventValue, currentInputIndex);
    }

    if (/^\d+$/.test(eventValue)) {
      if (eventValue.length === 1) {
        setValue(eventValue, currentInputIndex);
      }

      if (eventValue.charAt(0) === inputValue) {
        setValue(eventValue.charAt(1), currentInputIndex);
      } else if (eventValue.charAt(1) === inputValue) {
        setValue(eventValue.charAt(0), currentInputIndex);
      }
      if (currentInputIndex !== inputRefs.length - 1) {
        inputRefs[currentInputIndex + 1].focus();
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && e.target.value === "") {
      const currentInputIndex = inputRefs.indexOf(e.target);
      if (currentInputIndex !== 0) {
        inputRefs[currentInputIndex - 1].focus();
      }
    }
  };

  // Below there isn't much for you to worry about.

  React.useEffect(
    () => onChange(inputValues.join("")),
    [onChange, inputValues]
  );

  const register = React.useCallback((ref) => {
    setInputRefs((inputRefs) => inputRefs.concat(ref));
  }, []);

  return (
    <Grid container justifyContent="space-between">
      {React.Children.map(children, (child, i) =>
        React.createElement(child.type, {
          ref: register,
          onChange: handleChange,
          onKeyDown: handleKeyDown,
          value: inputValues[i],
        })
      )}
    </Grid>
  );
}

const PinInputField = React.forwardRef((props, ref) => (
  <Grid item container justifyContent="center" xs={2}>
    <TextField
      required
      inputProps={{
        inputMode: "numeric",
        minLength: 1,
        maxLength: 1,
        style: {
          textAlign: "center",
          typography: "h3",
        },
      }}
      sx={{
        maxWidth: { xs: "40px", md: "50px" },
        "& .MuiInputBase-input": {
          px: 0,
        },
      }}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown}
      value={props.value}
      inputRef={ref}
    />
  </Grid>
));

const InputOTP = ({ pinChangeHandler }) => {
  const handlePINChange = (newPin) => {
    pinChangeHandler(newPin);
  };

  return (
    <PinInput onChange={(value) => handlePINChange(value)}>
      <PinInputField />
      <PinInputField />
      <PinInputField />
      <PinInputField />
      <PinInputField />
      <PinInputField />
    </PinInput>
  );
};

export default InputOTP;
