const bizTypeList = [
  {
    id: 1,
    name: 'Makanan & Minuman',
    isCategory: true,
  },
  {
    id: 2,
    name: 'Kafe & Coffee Shop',
    isCategory: false,
  },
  {
    id: 3,
    name: 'Restoran',
    isCategory: false,
  },
  {
    id: 4,
    name: 'Katering',
    isCategory: false,
  },
  {
    id: 5,
    name: 'Roti & Kue',
    isCategory: false,
  },
  {
    id: 6,
    name: 'Makanan Segar',
    isCategory: false,
  },
  {
    id: 7,
    name: 'Minuman',
    isCategory: false,
  },
  {
    id: 8,
    name: 'Retail',
    isCategory: true,
  },
  {
    id: 9,
    name: 'Toko Kelontong',
    isCategory: false,
  },
  {
    id: 10,
    name: 'Minimarketing',
    isCategory: false,
  },
  {
    id: 11,
    name: 'Olahraga & Hobi',
    isCategory: false,
  },
  {
    id: 12,
    name: 'Toko Elektronik & Selular',
    isCategory: false,
  },
  {
    id: 13,
    name: 'Butik & Pakaian',
    isCategory: false,
  },
  {
    id: 14,
    name: 'Aksesoris & Desain',
    isCategory: false,
  },
  {
    id: 15,
    name: 'Furnitur & Bahan Bangunan',
    isCategory: false,
  },
  {
    id: 16,
    name: 'Jasa & Pelayanan',
    isCategory: true,
  },
  {
    id: 17,
    name: 'Laundry',
    isCategory: false,
  },
  {
    id: 18,
    name: 'Printing & Fotokopi',
    isCategory: false,
  },
  {
    id: 19,
    name: 'Bengkel & Cuci Steam',
    isCategory: false,
  },
  {
    id: 20,
    name: 'Salon & Barbershop',
    isCategory: false,
  },
  {
    id: 21,
    name: 'Kesehatan & Kecantikan',
    isCategory: false,
  },
  {
    id: 22,
    name: 'Travel & Tours',
    isCategory: false,
  },
  {
    id: 23,
    name: 'Tipe Bisnis Lainnya',
    isCategory: true,
  },
  {
    id: 24,
    name: 'Lainnya',
    isCategory: false,
  },
];

export default bizTypeList;
