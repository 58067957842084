import { queryClient, useMutation, useQuery } from '@tanstack/react-query';

import {
  collection,
  doc,
  query,
  where,
  getDocs,
  orderBy,
  limit,
} from 'firebase/firestore';
import { db } from '../../firebase';

// import auth components
import { useUserAuth } from '../../context/UserAuthContext';

export const useGetNotifications = () => {
  const { user } = useUserAuth();

  return useQuery({
    queryKey: ['notifications'],
    queryFn: async () => {
      const q = query(
        collection(db, 'notifications'),
        where('userRef', '==', doc(db, 'users', user.uid)),
        orderBy('createdAt', 'desc'),
        limit(20)
      );

      const querySnapshot = await getDocs(q);
      const notifications = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const qBroad = query(
        collection(db, 'broadcast_notifications'),
        orderBy('createdAt', 'desc'),
        limit(20)
      );

      const querySnapshotBroad = await getDocs(qBroad);
      const broadNotifications = querySnapshotBroad.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return {
        notifications: notifications,
        broadcastNotifications: broadNotifications,
      };
    },
  });
};

export const useGetNotificationInfo = () => {
  const { user } = useUserAuth();

  return useQuery({
    queryKey: ['notificationInfo'],
    queryFn: async () => {
      const q = query(
        collection(db, 'notification_infos'),
        where('userRef', '==', doc(db, 'users', user.uid))
      );

      const querySnapshot = await getDocs(q);
      const notificationInfos = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return notificationInfos[0];
    },
  });
};
