import React from 'react';

// mui components
import {
  Box,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const TncKpi = ({
  tncModalOpen,
  handleTncModalClose,
  handleCloseAllTncModals,
}) => {
  return (
    <Modal open={tncModalOpen} onClose={handleTncModalClose}>
      <Paper
        sx={{
          mx: 'auto',
          mt: '10vh',
          p: 4,
          width: { xs: '80vw', md: 400 },
          backgroundColor: 'primary.mudahWhite',
        }}
      >
        <Stack justifyContent="flex-end">
          <IconButton
            aria-label="close"
            sx={{ ml: 'auto' }}
            onClick={handleCloseAllTncModals}
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        <Typography variant="h6" component="h2" fontWeight="bold">
          Keamanan dan Perlindungan Informasi
        </Typography>

        <Typography
          overflow="auto"
          paragraph={true}
          variant="body2"
          sx={{
            maxHeight: '350px',
            mt: 2,
          }}
        >
          1. Menerbitkan, mendistribusikan atau memperbanyak dengan cara apapun
          materi yang dilindungi hak cipta, merek dagang, atau informasi lain
          yang PT Hidup Mudah Indonesia miliki tanpa persetujuan tertulis dan
          tegas terlebih dahulu dari PT Hidup Mudah Indonesia atau pemilik hak
          yang melisensikan haknya kepada PT Hidup Mudah Indonesia;
          <br />
          <br />
          2. Menggunakan dan/atau mengakses secara tidak resmi sistem, aplikasi
          maupun data milik PT Hidup Mudah Indonesia untuk:
          <br />
          a) Merusak, melemahkan atau membahayakan setiap aspek dari aplikasi
          dan/atau sistem dan jaringan terkait; b) Membuat produk atau layanan
          tandingan serupa menggunakan ide, fitur, fungsi atau grafik menyerupai
          sistem, aplikasi maupun data milik PT Hidup Mudah Indonesia; c)
          Melakukan hal-hal yang mengakibatkan kerusakan terhadap sistem,
          aplikasi maupun data milik PT Hidup Mudah Indonesia dengan sengaja;
          atau d) Melakukan hal-hal yang bertujuan untuk mencuri data PT Hidup
          Mudah Indonesia dan/atau Pelanggan. e) Mitra Usaha bertanggung jawab
          dan menjamin bahwa Mitra Usaha tidak akan meminta data dan/atau
          informasi kepada pelanggan atau pihak lain melalui sarana apapun
          dengan mengatasnamakan PT Hidup Mudah Indonesia tanpa persetujuan
          tertulis terlebih dahulu dari PT Hidup Mudah Indonesia.
          <br />
          <br />
          3. Mitra Usaha bertanggung jawab dan menjamin sepenuhnya atas
          kebenaran data dan/atau keterangan dan/atau dokumen lainnya yang
          diserahkan oleh Mitra Usaha kepada PT Hidup Mudah Indonesia berkaitan
          dengan penyediaan Layanan berdasarkan Perjanjian Layanan, dan Mitra
          Usaha dengan ini membebaskan dan melepaskan PT Hidup Mudah Indonesia
          dari segala klaim, gugatan dan tuntutan hukum dalam bentuk apapun baik
          di dalam maupun di luar pengadilan yang timbul dari pihak manapun
          termasuk dari Mitra Usaha sehubungan dengan setiap informasi dan/atau
          data yang disampaikan oleh Mitra Usaha kepada PT Hidup Mudah
          Indonesia.
          <br />
          <br />
          <Box component="span" fontWeight="bold">
            Kerahasiaan
          </Box>
          <br />
          <br />
          1. Salah satu Pihak dapat memberikan Informasi Rahasia kepada Pihak
          lainnya dalam Ketentuan Penggunaan Mudah Indonesia ini. Para Pihak
          sepakat bahwa pemberian, penerimaan dan penggunaan Informasi Rahasia
          tersebut dilakukan sesuai dengan ketentuan yang diatur dalam pasal
          ini.
          <br />
          <br />
          2. Seluruh Informasi Rahasia dan data terkait dengan Ketentuan Mudah
          Indonesia ini yang diterima oleh Para Pihak harus dijaga
          kerahasiaannya dan Para Pihak sepakat untuk tidak memberitahukan
          dan/atau memberikan data sebagian ataupun seluruhnya kepada pihak
          ketiga manapun juga atau menggunakan informasi dan data tersebut untuk
          kepentingan Para Pihak yang tidak berhubungan dengan Ketentuan
          Penggunaan Mudah Indonesia ini, kecuali:
          <br />
          a) Atas persetujuan tertulis dari Pihak lainnya; b) Informasi yang
          diungkapkan oleh suatu Pihak kepada pegawainya, banknya, konsultan
          finansialnya, konsultan hukumnya, atau konsultan lainnya sehubungan
          dengan Ketentuan Penggunaan Mudah Indonesia ini; c) Data tersebut
          sudah merupakan informasi milik umum, bukan karena kesalahan Pihak
          yang menerima informasi; d) Berdasarkan ketetapan pengadilan atau
          arbitrase; dan/atau e) Diberikan oleh PT Hidup Mudah Indonesia kepada
          Afiliasinya, Penyedia Layanan dan/atau pihak yang memiliki hubungan
          kerjasama dengan PT Hidup Mudah Indonesia untuk pelaksanaan penyediaan
          Aplikasi Mudah Indonesia dan/atau Layanan dan juga untuk dapat
          memberikan penawaran produk atau layanan lainnya dari PT Hidup Mudah
          Indonesia.
          <br />
          <br />
          3. Mitra Usaha sepakat bahwa PT Hidup Mudah Indonesia berhak untuk (1)
          memberitahukan segala data dan Informasi Rahasia kepada vendor,
          subkontraktor, agen, konsultan semata-mata untuk keperluan pelaksanaan
          Ketentuan Penggunaan Mudah Indonesia ini; dan/atau (2) mengolah dan
          menggunakan data Mitra Usaha untuk:
          <br />
          a) Meningkatkan layanan PT Hidup Mudah Indonesia dan/atau Afilasinya
          dan/atau Penyedia Layanan kepada Mitra Usaha (termasuk untuk sistem
          fraud, fraud rules dan keperluan audit korporasi); serta b) Melakukan
          penawaran produk dan/atau layanan PT Hidup Mudah Indonesia dan/atau
          Afilasinya dan/atau Penyedia Layanan lainnya kepada Mitra Usaha.
          <br />
          <br />
          <Box component="span" fontWeight="bold">
            Ketentuan Lain-Lain
          </Box>
          <br />
          <br />
          1. Apabila terdapat suatu ketidaksesuaian dan/atau perbedaan dalam
          Ketentuan Penggunaan Mudah Indonesia ini dengan Syarat dan Ketentuan
          Khusus, maka para pihak sepakat dan memahami bahwa yang berlaku dan
          mengikat adalah ketentuan-ketentuan pada Syarat dan Ketentuan Khusus
          sesuai dengan Layanan yang dipilih.
          <br />
          <br />
          2. Masing-masing pihak dari PT Hidup Mudah Indonesia dan/atau
          Afilasinya dengan ini dapat, dengan pemberitahuan kepada Mitra Usaha,
          melakukan perjumpaan hutang sewaktu-waktu dengan hutang Mitra Usaha
          yang belum dibayar kepada salah satu pihak dari PT Hidup Mudah
          Indonesia berdasarkan Ketentuan Penggunaan Mudah Indonesia dan Syarat
          dan Ketentuan Khusus ini atau sebagaimana yang secara tegas diizinkan
          oleh hukum yang berlaku, kecuali jumlah tersebut saat ini sedang dalam
          sengketa (dan belum diselesaikan) oleh Para Pihak.
          <br />
          <br />
          3. Masing-masing pihak dari PT Hidup Mudah Indonesia dan/atau
          Afilasinya mencadangkan setiap hak yang dimiliki PT Hidup Mudah
          Indonesia dan/atau Afiliasinya berdasarkan syarat dan ketentuan yang
          berlaku dari setiap fitur dan/atau Layanan yang dipilih. Pelanggaran
          Mitra Usaha atas salah satu syarat dan ketentuan yang berlaku dari
          salah satu fitur dan/atau Layanan yang dipilih akan dianggap sebagai
          pelanggaran terhadap Ketentuan Penggunaan Mudah Indonesia ini dan
          karenanya menimbulkan hak bagi masing-masing pihak dari PT Hidup Mudah
          Indonesia untuk menjalankan hak-haknya berdasarkan Ketentuan
          Penggunaan Mudah Indonesia ini.
          <br />
          <br />
          4. Mitra Usaha tidak dapat mengalihkan haknya berdasarkan Ketentuan
          Penggunaan Mudah Indonesia ini tanpa persetujuan tertulis dari PT
          Hidup Mudah Indonesia. Namun, PT Hidup Mudah Indonesia dapat
          mengalihkan hak dan kewajiban PT Hidup Mudah Indonesia berdasarkan
          Ketentuan Penggunaan Mudah Indonesia ini setiap saat kepada pihak
          lain, termasuk Afiliasinya, tanpa perlu mendapatkan persetujuan
          terlebih dahulu dari atau memberikan pemberitahuan sebelumnya kepada
          Mitra Usaha.
          <br />
          <br />
          5. Dengan tunduk kepada Syarat dan Ketentuan Khusus, PT Hidup Mudah
          Indonesia dapat sewaktu-waktu mengubah, melengkapi, mengganti dan/atau
          menambah ketentuan yang terdapat dalam Ketentuan Penggunaan Mudah
          Indonesia, atas kebijakannya sendiri dan akan melakukan upaya yang
          wajar untuk memberikan informasi kepada Mitra Usaha terhadap setiap
          perubahan (amandemen) atau penambahan (addendum) dengan cara-cara
          berikut ini:
          <br />
          a) Dalam hal pemberitahuan dilakukan melalui e-mail, 2 (dua) jam
          setelah e-mail dikirimkan (sebagaimana yang dicatat oleh perangkat
          yang dipakai oleh pengirim e-mail), kecuali pengirim menerima pesan
          otomatis bahwa email tidak berhasil terkirim. b) Dalam hal
          pemberitahuan dilakukan melalui aplikasi, ketika push notification
          dan/atau media pemberitahuan lainnya di aplikasi telah dikeluarkan
          sebagaimana dibuktikan melalui sistem PT Hidup Mudah Indonesia. c)
          Dalam hal pemberitahuan dilakukan melalui sarana selain yang
          dicantumkan dalam poin a dan b, maka akan mengikuti ketentuan yang
          ditetapkan dalam pemberitahuan tersebut.
          <br />
          <br />
          Atas segala perubahan (amandemen) atau penambahan (addendum) atas
          Ketentuan Penggunaan Mudah Indonesia ini akan berlaku ketika PT Hidup
          Mudah Indonesia mengumumkan atau memberitahukan perubahan (amandemen)
          atau penambahan (addendum) tersebut. Mitra Usaha mengakui dan
          menyetujui bahwa Mitra Usaha bertanggung jawab untuk meninjau secara
          berkala segala Ketentuan Penggunaan Mudah Indonesia dan/atau Syarat
          dan Ketentuan Khusus yang berlaku baginya. Setiap perubahan atas
          Ketentuan Penggunaan Mudah Indonesia dan/atau Syarat dan Ketentuan
          Khusus, akan mengikat Mitra Usaha, dan keberlanjutan penggunaan dari
          Layanan yang dipilih oleh Mitra Usaha akan dianggap sebagai
          persetujuan atas perubahan tersebut.
          <br></br>
          <br></br>
          Jika Anda memiliki pertanyaan Keamanan dan Perlindungan Informasi ini,
          Anda dapat menghubungi kami melalui email: info@mudahindonesia.com
          <br></br>
          <br></br>
          Diperbaharui pada tanggal: 3 September 2023
        </Typography>
      </Paper>
    </Modal>
  );
};

export default TncKpi;
