import React from 'react';

// import page components
import MudahCopyright from '../../components/MudahCopyright';

// import static assets
import BgRight from '../../../assets/bg-right.png';

// import ui components
import { Box, Container, Paper } from '@mui/material';

const AuthBox = ({ children }) => {
  return (
    <Box
      sx={{
        width: 1,
        minHeight: '100vh',
        backgroundImage: `url(${BgRight})`,
        backgroundPosition: 'bottom 0 right 0',
        backgroundSize: '40%',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper
          elevation={4}
          sx={{
            my: { xs: 3, md: 6 },
            px: { xs: 3, md: 5 },
            py: { xs: 3, md: 5 },
            // height: "80vh",
          }}
        >
          {children}
        </Paper>
        <MudahCopyright />
      </Container>
    </Box>
  );
};

export default AuthBox;
