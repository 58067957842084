import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUserAuth } from '../../context/UserAuthContext';

const AuthenticatedRoute = ({ children }) => {
  const { user, pinAuthenticated } = useUserAuth();

  if (user === undefined) {
    return <></>;
  }
  if (user && pinAuthenticated) {
    return <Navigate to="/" />;
  }
  return children;
};

export default AuthenticatedRoute;
