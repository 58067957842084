import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  FaHome,
  FaQrcode,
  FaWhatsapp,
  FaRegQuestionCircle,
  FaUserCircle,
  FaSignOutAlt,
} from 'react-icons/fa';
import { GrTransaction } from 'react-icons/gr';
import { MdAccountBalanceWallet } from 'react-icons/md';

// import auth components
import { useUserAuth } from '../context/UserAuthContext';

// import static assets
import './Sidebar.css';
import MudahLogo from '../assets/mudah-logo.png';

// import mui components
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';

const Sidebar = () => {
  let activeStyle = {
    color: '#0049ad',
  };

  const [verified, setVerified] = useState('none');
  const [dataLoading, setDataLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const { userData } = useUserAuth();

  useEffect(() => {
    const getUserVerification = async () => {
      const profileData = await userData();
      setVerified(profileData.verified);
      setDataLoading(false);
    };
    getUserVerification();
  }, [userData]);

  return (
    <nav>
      {dataLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 200,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Link to="/">
            <img src={MudahLogo} alt="Mudah Logo" className="nav-img" />
          </Link>

          {verified !== 'verified' ? (
            <ul id="topNav"></ul>
          ) : (
            <ul id="topNav">
              <Tooltip
                title={
                  <Typography
                    sx={{ fontSize: 18, color: 'primary.mudahWhite' }}
                  >
                    Beranda
                  </Typography>
                }
                placement="right"
              >
                <NavLink
                  to="/"
                  className="nav-icon"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  <li>{<FaHome />}</li>
                </NavLink>
              </Tooltip>

              <Tooltip
                title={
                  <Typography
                    sx={{ fontSize: 18, color: 'primary.mudahWhite' }}
                  >
                    QRIS
                  </Typography>
                }
                placement="right"
              >
                <NavLink
                  to="/qris"
                  className="nav-icon"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  <li>{<FaQrcode />}</li>
                </NavLink>
              </Tooltip>

              <Tooltip
                title={
                  <Typography
                    sx={{ fontSize: 18, color: 'primary.mudahWhite' }}
                  >
                    Transaksi
                  </Typography>
                }
                placement="right"
              >
                <NavLink
                  to="/transaction"
                  className="nav-icon"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  <li>{<GrTransaction />}</li>
                </NavLink>
              </Tooltip>

              <Tooltip
                title={
                  <Typography
                    sx={{ fontSize: 18, color: 'primary.mudahWhite' }}
                  >
                    Saldo
                  </Typography>
                }
                placement="right"
              >
                <NavLink
                  to="/balance"
                  className="nav-icon"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  <li>{<MdAccountBalanceWallet />}</li>
                </NavLink>
              </Tooltip>
            </ul>
          )}

          <ul className="botNav">
            <Tooltip
              title={
                <Typography sx={{ fontSize: 18, color: 'primary.mudahWhite' }}>
                  Kontak WhatsApp
                </Typography>
              }
              placement="right"
            >
              <a
                href="https://api.whatsapp.com/send?phone=6281318824067"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-bot-icon"
              >
                <li>{<FaWhatsapp />}</li>
              </a>
            </Tooltip>

            {/* {verified !== 'verified' ? (
              <></>
            ) : (
              <Tooltip
                title={
                  <Typography
                    sx={{ fontSize: 18, color: 'primary.mudahWhite' }}
                  >
                    Pusat Bantuan
                  </Typography>
                }
                placement="right"
              >
                <a
                  href="https://mudahindonesia.com/#FAQ"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-bot-icon"
                >
                  <li>{<FaRegQuestionCircle />}</li>
                </a>
              </Tooltip>
            )} */}

            {verified !== 'verified' ? (
              <></>
            ) : (
              <Tooltip
                title={
                  <Typography
                    sx={{ fontSize: 18, color: 'primary.mudahWhite' }}
                  >
                    Profil
                  </Typography>
                }
                placement="right"
              >
                <Link to="/profile" className="nav-bot-icon">
                  <li>{<FaUserCircle />}</li>
                </Link>
              </Tooltip>
            )}

            <Tooltip
              title={
                <Typography sx={{ fontSize: 18, color: 'primary.mudahWhite' }}>
                  Keluar
                </Typography>
              }
              placement="right"
            >
              <Container className="nav-bot-icon" onClick={handleOpen}>
                <li>{<FaSignOutAlt />}</li>
              </Container>
            </Tooltip>
          </ul>

          {/* Logout modal */}
          <Modal open={openModal} onClose={handleClose}>
            <Paper
              sx={{
                mx: 'auto',
                mt: '10vh',
                p: 4,
                width: '80vw',
                backgroundColor: 'primary.mudahWhite',
              }}
            >
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ textAlign: 'center' }}
                  >
                    Apakah Kamu yakin ingin keluar?
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    component={Link}
                    to="/logout"
                    variant="outlined"
                    fullWidth
                    sx={{
                      backgroundColor: 'primary.mudahLightGrey',
                      color: 'primary.mudahBlack',
                      border: '0',
                      borderRadius: '30px',
                    }}
                  >
                    Ya
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: 'primary.mudahRed',
                      borderRadius: '30px',
                    }}
                    onClick={handleClose}
                  >
                    Tidak
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Modal>
        </>
      )}
    </nav>
  );
};

export default Sidebar;
