import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBell } from 'react-icons/fa';

// import mui components
import { Badge, Grid, Typography } from '@mui/material';

// import static assets
import './Header.css';

import { useGetNotificationInfo } from '../hooks/services/notifications';

const Header = ({ pageTitle }) => {
  const [notificationInfo, setNotificationInfo] = useState([]);

  const { isSuccess, data } = useGetNotificationInfo();

  useEffect(() => {
    if (isSuccess) {
      setNotificationInfo(data);
    }
  }, [isSuccess, data]);

  return (
    <header>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h1" fontWeight="bold">
            {pageTitle}
          </Typography>
        </Grid>
        <Grid item>
          <Link to="/notification" className="NotificationIcon">
            {(notificationInfo?.notReadCount ?? 0) > 0 ? (
              <Badge
                badgeContent={notificationInfo?.notReadCount}
                color="primary"
              >
                <FaBell className="BellIcon" />
              </Badge>
            ) : (
              <FaBell className="BellIcon" />
            )}
          </Link>
        </Grid>
      </Grid>
    </header>
  );
};

export default Header;
