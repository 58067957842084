import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

import Header from '../../layouts/Header';
import Sidebar from '../../layouts/Sidebar';

// import static assets
import '../css/MainContainer.css';

// import components
import MudahCopyright from '../components/MudahCopyright';

// import mui components
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

// import data hooks
import { useGetTransactions } from '../../hooks/services/transaction';

const initialTransactions = [];

const Transaction = () => {
  const [dateFilter, setDateFilter] = useState('all');
  const [transactions, setTransactions] = useState(initialTransactions);

  const { isSuccess, data } = useGetTransactions();

  const handleDateFilter = (event) => {
    setDateFilter(event.target.value);
  };

  useEffect(() => {
    if (isSuccess) {
      setTransactions(data);
    }
  }, [isSuccess, data]);

  return (
    <>
      <Sidebar />
      <div className="MainContainer">
        <Header pageTitle="Transaksi" />
        <Paper elevation={4} sx={{ mt: 2, p: { xs: '15px', md: '35px' } }}>
          <Box sx={{ mx: 'auto', maxWidth: 750 }}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item xs={12} md={8}>
                <TextField
                  id="search-box"
                  label="Cari Transaksi"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="date-filter-label">
                    Tanggal Transaksi
                  </InputLabel>
                  <Select
                    id="date-filter"
                    label="Tanggal Transaksi"
                    value={dateFilter}
                    onChange={handleDateFilter}
                  >
                    <MenuItem value="all">Semua</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {!isSuccess ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 200,
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                  <TableBody>
                    {transactions.map((t) => (
                      <TableRow
                        key={t.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Hidden mdDown>
                            {t.status === 'paid' ? (
                              <Box
                                sx={{
                                  m: 'auto',
                                  width: { xs: 15, md: 20 },
                                  height: { xs: 15, md: 20 },
                                  borderRadius: 7,
                                  backgroundColor: 'primary.mudahGreen',
                                }}
                              />
                            ) : (
                              <Box
                                sx={{
                                  m: 'auto',
                                  width: { xs: 15, md: 20 },
                                  height: { xs: 15, md: 20 },
                                  borderRadius: 7,
                                  backgroundColor: 'primary.mudahDarkGrey',
                                }}
                              />
                            )}
                          </Hidden>
                          <Hidden mdUp>
                            {t.status === 'paid' ? (
                              <Box
                                sx={{
                                  ml: 'auto',
                                  width: 3,
                                  height: 90,
                                  backgroundColor: 'primary.mudahGreen',
                                }}
                              />
                            ) : (
                              <Box
                                sx={{
                                  ml: 'auto',
                                  width: 3,
                                  height: 90,
                                  backgroundColor: 'primary.mudahDarkGrey',
                                }}
                              />
                            )}
                          </Hidden>
                        </TableCell>
                        <TableCell align="left">
                          <Hidden smUp>
                            <Typography
                              variant="body1"
                              sx={{ color: 'primary.mudahDarkGrey' }}
                            >
                              {t.status === 'paid' ? 'Berhasil' : 'Menunggu'}
                            </Typography>
                            {t.status === 'paid' ? (
                              <Typography
                                variant="h3"
                                fontWeight="bold"
                                sx={{
                                  color: 'primary.mudahGreen',
                                  mb: 1,
                                }}
                              >
                                {t.value.toLocaleString('id-ID', {
                                  style: 'currency',
                                  currency: 'IDR',
                                })}
                              </Typography>
                            ) : (
                              <Typography
                                variant="h3"
                                fontWeight="bold"
                                sx={{
                                  color: 'primary.mudahDarkGrey',
                                  mb: 1,
                                }}
                              >
                                {t.value.toLocaleString('id-ID', {
                                  style: 'currency',
                                  currency: 'IDR',
                                })}
                              </Typography>
                            )}
                          </Hidden>
                          <Typography variant="h4" fontWeight="bold">
                            {t.qrisType === 'static'
                              ? 'QRIS Statis'
                              : 'QRIS Dinamis'}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ color: 'primary.mudahDarkGrey' }}
                          >
                            Dibuat:{' '}
                            {format(t.createdAt.toDate(), ' d MMM yyyy, HH:mm')}
                          </Typography>

                          <Typography
                            variant="body1"
                            sx={{ color: 'primary.mudahDarkGrey' }}
                          >
                            Ref: {t.id}
                          </Typography>
                        </TableCell>
                        <Hidden smDown>
                          <TableCell align="right">
                            {t.status === 'paid' ? (
                              <Typography
                                variant="h3"
                                fontWeight="bold"
                                sx={{
                                  color: 'primary.mudahGreen',
                                }}
                              >
                                {t.value.toLocaleString('id-ID', {
                                  style: 'currency',
                                  currency: 'IDR',
                                })}
                              </Typography>
                            ) : (
                              <Typography
                                variant="h3"
                                fontWeight="bold"
                                sx={{
                                  color: 'primary.mudahDarkGrey',
                                }}
                              >
                                {t.value.toLocaleString('id-ID', {
                                  style: 'currency',
                                  currency: 'IDR',
                                })}
                              </Typography>
                            )}
                            <Typography
                              variant="body1"
                              sx={{ color: 'primary.mudahDarkGrey' }}
                            >
                              {t.status === 'paid'
                                ? 'Berhasil'
                                : 'Tidak Berlaku'}
                            </Typography>
                          </TableCell>
                        </Hidden>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Paper>
        <MudahCopyright />
      </div>
    </>
  );
};

export default Transaction;
