import React from "react";

// import mui components
import Grid from "@mui/material/Grid";

// import static assets
import MudahLogo from "../../assets/mudah-logo.png";
import MudahText from "../../assets/mudah-text.png";

const MudahLogoPair = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing="8"
    >
      <Grid item>
        <img src={MudahLogo} alt="Mudah Logo" style={{ height: "35px" }} />
      </Grid>
      <Grid item>
        <img src={MudahText} alt="Mudah Text" style={{ height: "25px" }} />
      </Grid>
    </Grid>
  );
};

export default MudahLogoPair;
