const bankList = {
  bank: {
    harda: 'Allo Bank/Bank Harda Internasional',
    anz: 'ANZ Indonesia',
    aceh: 'Bank Aceh Syariah',
    aladin: 'Bank Aladin Syariah',
    antardaerah: 'Bank Antardaerah',
    artha: 'Bank Artha Graha Internasional',
    bengkulu: 'Bank Bengkulu',
    daerah_istimewa: 'Bank BPD DIY',
    daerah_istimewa_syr: 'Bank BPD DIY Syariah',
    btpn_syr: 'Bank BTPN Syariah',
    bukopin_syr: 'Bank Bukopin Syariah',
    bumi_arta: 'Bank Bumi Arta',
    capital: 'Bank Capital Indonesia',
    bca: 'BCA (Bank Central Asia)',
    ccb: 'Bank China Construction Bank Indonesia',
    cnb: 'Bank CNB (Centratama Nasional Bank)',
    danamon: 'Bank Danamon & Danamon Syariah',
    dinar: 'Bank Dinar Indonesia',
    dki: 'Bank DKI',
    dki_syr: 'Bank DKI Syariah',
    ganesha: 'Bank Ganesha',
    agris: 'Bank IBK Indonesia',
    ina_perdana: 'Bank Ina Perdana',
    index_selindo: 'Bank Index Selindo',
    artos_syr: 'Bank Jago Syariah',
    jambi: 'Bank Jambi',
    jambi_syr: 'Bank Jambi Syariah',
    jasa_jakarta: 'Bank Jasa Jakarta',
    jawa_tengah: 'Bank Jateng',
    jawa_tengah_syr: 'Bank Jateng Syariah',
    jawa_timur: 'Bank Jatim',
    jawa_timur_syr: 'Bank Jatim Syariah',
    kalimantan_barat: 'Bank Kalbar',
    kalimantan_barat_syr: 'Bank Kalbar Syariah',
    kalimantan_selatan: 'Bank Kalsel',
    kalimantan_selatan_syr: 'Bank Kalsel Syariah',
    kalimantan_tengah: 'Bank Kalteng',
    kalimantan_timur_syr: 'Bank Kaltim Syariah',
    kalimantan_timur: 'Bank Kaltimtara',
    lampung: 'Bank Lampung',
    maluku: 'Bank Maluku',
    mandiri: 'Bank Mandiri',
    mantap: 'Bank MANTAP (Mandiri Taspen)',
    maspion: 'Bank Maspion Indonesia',
    mayapada: 'Bank Mayapada',
    mayora: 'Bank Mayora Indonesia',
    mega: 'Bank Mega',
    mega_syr: 'Bank Mega Syariah',
    mestika_dharma: 'Bank Mestika Dharma',
    mas: 'Bank Multi Arta Sentosa (Bank MAS)',
    mutiara: 'Bank Mutiara',
    sumatera_barat: 'Bank Nagari',
    sumatera_barat_syr: 'Bank Nagari Syariah',
    nusa_tenggara_barat: 'Bank NTB Syariah',
    nusa_tenggara_timur: 'Bank NTT',
    nusantara_parahyangan: 'Bank Nusantara Parahyangan',
    ocbc: 'Bank OCBC NISP',
    ocbc_syr: 'Bank OCBC NISP Syariah',
    boc: 'Bank of China (Hong Kong) Limited',
    india: 'Bank of India Indonesia',
    tokyo: 'Bank of Tokyo Mitsubishi UFJ',
    papua: 'Bank Papua',
    prima: 'Bank Prima Master',
    bri: 'BRI (Bank Rakyat Indonesia)',
    riau_dan_kepri: 'Bank Riau Kepri',
    sahabat_sampoerna: 'Bank Sahabat Sampoerna',
    shinhan: 'Bank Shinhan Indonesia',
    sinarmas: 'Bank Sinarmas',
    sinarmas_syr: 'Bank Sinarmas Syariah',
    sulselbar: 'Bank Sulselbar',
    sulselbar_syr: 'Bank Sulselbar Syariah',
    sulawesi: 'Bank Sulteng',
    sulawesi_tenggara: 'Bank Sultra',
    sulut: 'Bank SulutGo',
    sumsel_dan_babel: 'Bank Sumsel Babel',
    sumsel_dan_babel_syr: 'Bank Sumsel Babel Syariah',
    sumut: 'Bank Sumut',
    sumut_syr: 'Bank Sumut Syariah',
    victoria_internasional: 'Bank Victoria International',
    victoria_syr: 'Bank Victoria Syariah',
    woori: 'Bank Woori Saudara',
    bca_syr: 'BCA Syariah',
    bjb: 'BJB',
    bjb_syr: 'BJB Syariah',
    royal: 'Blu by BCA Digital',
    bni: 'BNI (Bank Negara Indonesia)',
    bali: 'BPD Bali',
    banten: 'BPD Banten',
    eka: 'BPR EKA (Bank Eka)',
    agroniaga: 'BRI Agroniaga',
    bsm: 'BSI (Bank Syariah Indonesia)',
    btn: 'BTN/BTN Syariah',
    tabungan_pensiunan_nasional: 'BTPN',
    cimb: 'CIMB Niaga & CIMB Niaga Syariah',
    citibank: 'Citibank',
    commonwealth: 'Commonwealth Bank',
    chinatrust: 'CTBC (Chinatrust) Indonesia',
    dbs: 'DBS Indonesia',
    hsbc: 'HSBC Indonesia',
    icbc: 'ICBC Indonesia',
    artos: 'Jago/Artos',
    hana: 'LINE Bank/KEB Hana',
    bii: 'Maybank Indonesia',
    bii_syr: 'Maybank Syariah',
    mnc_internasional: 'Motion/MNC Bank',
    muamalat: 'Muamalat',
    yudha_bakti: 'Neo Commerce/Yudha Bhakti',
    nationalnobu: 'Nobu (Nationalnobu) Bank',
    panin: 'Panin Bank',
    panin_syr: 'Panin Dubai Syariah',
    permata: 'Permata',
    permata_syr: 'Permata Syariah',
    qnb_kesawan: 'QNB Indonesia',
    rabobank: 'Rabobank International Indonesia',
    sbi_indonesia: 'SBI Indonesia',
    kesejahteraan_ekonomi: 'Seabank/Bank BKE',
    standard_chartered: 'Standard Chartered Bank',
    uob: 'TMRW/UOB',
    bukopin: 'Wokee/Bukopin',
  },
  ewallet: {
    dana: 'Dana',
    gopay: 'GoPay',
    linkaja: 'LinkAja',
    ovo: 'OVO',
    shopeepay: 'ShopeePay',
  },
};

export default bankList;
