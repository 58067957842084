import React from 'react';

// mui components
import {
  Box,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const TncBtj = ({
  tncModalOpen,
  handleTncModalClose,
  handleCloseAllTncModals,
}) => {
  return (
    <Modal open={tncModalOpen} onClose={handleTncModalClose}>
      <Paper
        sx={{
          mx: 'auto',
          mt: '10vh',
          p: 4,
          width: { xs: '80vw', md: 400 },
          backgroundColor: 'primary.mudahWhite',
        }}
      >
        <Stack justifyContent="flex-end">
          <IconButton
            aria-label="close"
            sx={{ ml: 'auto' }}
            onClick={handleCloseAllTncModals}
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        <Typography variant="h6" component="h2" fontWeight="bold">
          Batasan Tanggung Jawab
        </Typography>

        <Typography
          overflow="auto"
          paragraph={true}
          variant="body2"
          sx={{
            maxHeight: '350px',
            mt: 2,
          }}
        >
          1. Mitra Usaha bertanggung jawab secara penuh atas setiap kerugian
          dan/atau klaim yang timbul dari penggunaan Situs Mudah Indonesia,
          Layanan, Konten Pihak Ketiga, Penawaran, atau Metode Pembayaran QRIS
          yang dipilih, diakses, atau digunakan oleh Akun Mitra Usaha di Situs
          Mudah Indonesia, baik oleh Mitra Usaha sendiri atau pihak lain yang
          menggunakan Akun Mitra Usaha, baik sesuai dengan atau bertentangan
          dengan ketentuan dalam Formulir Mitra Usaha, Ketentuan Penggunaan
          Mudah Indonesia ini, Syarat dan Ketentuan Khusus, Pemberitahuan
          Privasi, termasuk syarat dan ketentuan khusus masing-masing dan
          kebijakan privasi yang ditetapkan oleh Penyedia Layanan, Penyedia
          Konten Pihak Ketiga, Penyedia Promosi, dan Penyedia Metode Pembayaran,
          atau peraturan perundang-undangan yang berlaku, termasuk namun tidak
          terbatas pada tujuan anti pencucian uang, anti pendanaan terorisme,
          aktivitas kriminal, penipuan dalam bentuk apapun (termasuk namun tidak
          terbatas pada kegiatan phishing dan/atau social engineering),
          pelanggaran hak kekayaan intelektual, dan/atau aktivitas lain yang
          merugikan publik dan/atau pihak lain manapun atau yang dapat atau
          dianggap dapat merusak reputasi PT Hidup Mudah Indonesia maupun
          Afiliasinya.
          <br />
          <br />
          2. Mitra Usaha memahami dan menyetujui bahwa seluruh risiko yang
          timbul dari penggunaan Situs Mudah Indonesia, Layanan, Konten Pihak
          Ketiga, Penawaran, dan Metode Pembayaran QRIS sepenuhnya menjadi
          tanggung jawab Mitra Usaha, dan Mitra Usaha dengan ini setuju untuk
          melepaskan PT Hidup Mudah Indonesia dari segala tuntutan apapun
          sehubungan dengan kerusakan, gangguan, atau bentuk lain dari gangguan
          sistem yang disebabkan oleh akses tidak resmi oleh pihak lain.
          <br />
          <br />
          3. PT Hidup Mudah Indonesia tidak bertanggung jawab atas setiap
          kerugian atau kerusakan yang disebabkan oleh setiap kegagalan atau
          kesalahan yang dilakukan oleh Penyedia Layanan, Penyedia Konten Pihak
          Ketiga, Penyedia Promosi, atau Penyedia Metode Pembayaran QRIS,
          ataupun kegagalan atau kesalahan Mitra Usaha dalam mematuhi Ketentuan
          Penggunaan Mudah Indonesia, Syarat dan Ketentuan Khusus, dan/atau
          syarat dan ketentuan lain yang ditetapkan oleh Penyedia Layanan,
          Penyedia Konten Pihak Ketiga, Penyedia Promosi, atau Penyedia Metode
          Pembayaran QRIS.
          <br />
          <br />
          4. Mitra Usaha dengan tegas membebaskan PT Hidup Mudah Indonesia,
          termasuk namun tidak terbatas pada pejabat, direktur, komisaris,
          karyawan, dan agen PT Hidup Mudah Indonesia, dari setiap dan semua
          kewajiban, konsekuensi, kerugian baik materiil atau immateriil,
          tuntutan, biaya (termasuk biaya advokat), atau tanggung jawab hukum
          lainnya yang timbul atau mungkin timbul akibat pelanggaran Mitra Usaha
          terhadap Ketentuan Penggunaan Mudah Indonesia, Syarat dan Ketentuan
          Khusus, dan/atau syarat dan ketentuan lain yang ditetapkan oleh
          Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Promosi, atau
          Penyedia Metode Pembayaran QRIS.
          <br />
          <br />
          <Box component="span" fontWeight="bold">
            Penyelesaian Masalah
          </Box>
          <br />
          <br />
          1. Apabila Mitra Usaha mengalami gangguan sistem, mengetahui atau
          menduga bahwa Akun Mitra Usaha di Situs Mudah Indonesia diretas,
          digunakan, atau disalahgunakan oleh pihak lain, atau apabila perangkat
          telepon genggam atau tablet pribadi Mitra Usaha hilang, dicuri,
          diretas, atau terkena virus, segera laporkan kepada PT Hidup Mudah
          Indonesia agar PT Hidup Mudah Indonesia dapat segera mengambil
          tindakan yang diperlukan untuk menghindari penggunaan, penyalahgunaan,
          atau kerugian yang timbul atau mungkin timbul lebih lanjut. Mitra
          Usaha dapat segera menghubungi info@mudahindonesia.com.
          <br />
          <br />
          2. Atas Laporan yang diterima dari Mitra Usaha oleh PT Hidup Mudah
          Indonesia, maka PT Hidup Mudah Indonesia akan melakukan hal-hal
          berikut ini:
          <br />
          PT Hidup Mudah Indonesia akan meminta data dan informasi secara
          lengkap seperti ringkasan fakta yang terjadi, bukti-bukti yang Mitra
          Usaha miliki, nomor pesanan, informasi pribadi, atau informasi
          lainnya; a) Pencocokan Laporan, data, serta informasi yang diterima
          dengan data yang tercantum dalam sistem PT Hidup Mudah Indonesia; b)
          PT Hidup Mudah Indonesia berhak dengan kebijakannya sendiri untuk
          menerima atau menolak Laporan tersebut. c) Dalam hal PT Hidup Mudah
          Indonesia menerima, maka PT Hidup Mudah Indonesia akan menindaklanjuti
          Laporan Mitra Usaha, termasuk melakukan perbaikan sistem dan membantu
          melakukan tindakan yang diperlukan untuk mengurangi kerugian yang
          mungkin diderita oleh Mitra Usaha, termasuk dengan mencabut Akses
          Mitra Usaha ke Situs Mudah Indonesia, menghentikan sementara sistem
          Situs Mudah Indonesia terlebih dahulu. d) Dalam hal PT Hidup Mudah
          Indonesia menolak, maka PT Hidup Mudah Indonesia akan memberhentikan
          tindak lanjut terhadap Laporan Mitra Usaha.
          <br />
          <br />
          3. Apabila Mitra Usaha mengalami kendala atau masalah terkait Layanan,
          Konten Pihak Ketiga, Promosi, atau pembayaran melalui Metode
          Pembayaran QRIS, atau perlakuan Penyedia Layanan, Penyedia Konten
          Pihak Ketiga, Penyedia Promosi, atau Penyedia Metode Pembayaran QRIS,
          Mitra Usaha wajib secara langsung menghubungi pihak-pihak yang terkait
          tanpa melibatkan PT Hidup Mudah Indonesia.
          <br />
          <br />
          <Box component="span" fontWeight="bold">
            Tindakan Kecurangan
          </Box>
          <br />
          <br />
          1. "Tindakan Kecurangan” adalah adanya indikasi dan/atau terbukti
          terjadinya salah satu dari hal-hal berikut ini:
          <br />
          a) Adanya transaksi mencurigakan; b) Penyalahgunaan (abuse) dan/atau
          transaksi fiktif, baik yang dilakukan oleh Mitra Usaha dan/atau
          karyawannya; c) Bekerja sama dengan penipu (fraudster); dan/atau d)
          Penipuan (fraud) yang dilakukan oleh Mitra Usaha dan/atau karyawannya.
          <br />
          <br />
          2. Adanya indikasi dan/atau terbukti terjadinya Tindakan Kecurangan,
          memberikan hak bagi PT Hidup Mudah Indonesia, berdasarkan pertimbangan
          dan kebijakannya sendiri dengan atau tanpa pemberitahuan tertulis
          terlebih dahulu kepada Mitra Usaha, untuk:
          <br />
          a) Menghentikan setiap kegiatan promosi dan/atau pemasaran dengan
          Mitra Usaha; b) Menghentikan sementara Layanan yang diberikan
          berdasarkan Syarat dan Ketentuan Umum ini kepada Mitra Usaha; c)
          Menahan dan/atau menangguhkan Dana Settlement; d) Menolak pembayaran
          dan/atau memotong bagian dari Dana Settlement sebagai pemulihan atas
          kerugian yang dialami oleh PT Hidup Mudah Indonesia dan/atau pihak
          ketiga lainnya; e) Memasukkan Mitra Usaha ke dalam daftar hitam PT
          Hidup Mudah Indonesia; dan/atau f) Mengajukan klaim, gugatan, dan/atau
          tuntutan hukum dalam bentuk apapun atas setiap kerugian yang dialami
          oleh PT Hidup Mudah Indonesia.
          <br />
          <br />
          PT Hidup Mudah Indonesia dapat meminta Mitra Usaha untuk menyerahkan
          bukti pendukung sebagai bagian dari proses banding Mitra Usaha
          terhadap pelaksanaan dari hak-hak PT Hidup Mudah Indonesia tersebut,
          dimana bukti pendukung tersebut harus diserahkan oleh Mitra Usaha
          paling lambat 7 (tujuh) hari kalender setelah diwajibkan oleh PT Hidup
          Mudah Indonesia. PT Hidup Mudah Indonesia akan menilai bukti pendukung
          dan semata-mata atas kebijakannya sendiri memutuskan untuk melakukan
          Settlement atau terus menggunakan haknya sebagaimana disebutkan dalam
          ayat (2) Pasal ini.
          <br />
          <br />
          <Box component="span" fontWeight="bold">
            Pembekuan Sementara dan Pembekuan Permanen Akun
          </Box>
          <br />
          <br />
          1. Mitra Usaha dapat menghapus Aplikasi Web Mudah Indonesia dari
          telepon genggam dan/atau tablet Mitra Usaha setiap saat. PT Hidup
          Mudah Indonesia tidak memiliki kewajiban apapun kepada Mitra Usaha
          terhadap hal-hal yang timbul sejak penghapusan Aplikasi Web Mudah
          Indonesia, pembekuan sementara, atau pembekuan permanen Akun Mitra
          Usaha. Namun, Mitra Usaha tetap bertanggung jawab untuk menyelesaikan
          kewajiban-kewajiban Mitra Usaha yang telah timbul, termasuk namun
          tidak terbatas pada, setiap kewajiban yang mungkin timbul akibat
          adanya sengketa, tuntutan, atau tindakan hukum lainnya yang telah ada,
          sebelum tanggal penghapusan Aplikasi Web Mudah Indonesia, pembekuan
          sementara, atau pembekuan permanen Akun Mitra Usaha.
          <br />
          <br />
          2. Akun Mitra Usaha dapat dibekukan untuk sementara waktu atau dapat
          dibekukan secara permanen karena hal-hal, termasuk namun tidak
          terbatas pada, sebagai berikut:
          <br />
          a) Laporan Mitra Usaha bahwa Akun Mitra Usaha digunakan atau diduga
          digunakan atau disalahgunakan oleh orang lain; b) Laporan Mitra Usaha
          bahwa telepon genggam atau tablet pribadi Mitra Usaha hilang, dicuri,
          atau diretas; c) PT Hidup Mudah Indonesia mengetahui atau mempunyai
          alasan yang cukup untuk menduga bahwa Akun Web Mudah Indonesia Mitra
          Usaha telah dialihkan atau digunakan oleh orang lain; d) PT Hidup
          Mudah Indonesia mengetahui atau dengan alasan yang cukup menduga bahwa
          telah terjadi hal-hal yang menurut PT Hidup Mudah Indonesia telah atau
          dapat merugikan PT Hidup Mudah Indonesia, Mitra Usaha, Penyedia
          Layanan, atau pihak lainnya; e) Sistem PT Hidup Mudah Indonesia
          mendeteksi adanya tindakan yang tidak wajar dari penggunaan Akun Web
          Mudah Indonesia Mitra Usaha atau adanya kewajiban berdasarkan
          Ketentuan Penggunaan Mudah Indonesia, Syarat dan Ketentuan Khusus,
          syarat dan ketentuan Layanan lainnya, dan/atau Pemberitahuan Privasi
          yang tidak dipenuhi oleh Mitra Usaha; f) Mitra Usaha telah mengajukan
          atau diajukan pailit, berada dalam proses Penundaan Kewajiban
          Pembayaran Utang (PKPU), dalam proses likuidasi, atau mengalami
          ketidakmampuan lainnya yang menjadikan Mitra Usaha tidak cakap hukum
          berdasarkan peraturan perundang-undangan yang berlaku; g) Penggunaan
          Aplikasi Web Mudah Indonesia atau Layanan oleh Mitra Usaha atau pihak
          lain (yang menggunakan Akun Mitra Usaha) dengan cara yang bertentangan
          dengan Ketentuan Penggunaan Mudah Indonesia ini, Pemberitahuan
          Privasi, atau peraturan perundang-undangan yang berlaku; dan/atau h)
          Perintah untuk pembekuan akun, baik sementara atau permanen, yang
          diterbitkan oleh institusi pemerintah atau moneter terkait atau
          berdasarkan perintah pengadilan yang diterbitkan sesuai dengan
          peraturan perundang-undangan yang berlaku.
          <br />
          <br />
          3. Jika Akun Web Mudah Indonesia Mitra Usaha dibekukan dan Mitra Usaha
          memiliki bukti yang jelas bahwa Akun Web Mudah Indonesia Mitra Usaha
          seharusnya tidak dibekukan, Mitra Usaha dapat membuat Laporan kepada
          PT Hidup Mudah Indonesia untuk menyampaikan bukti-bukti tersebut.
          Setelah melakukan pemeriksaan lebih lanjut terhadap Laporan Mitra
          Usaha, PT Hidup Mudah Indonesia akan, atas kebijakan PT Hidup Mudah
          Indonesia sepenuhnya, menentukan untuk mengakhiri atau melanjutkan
          pembekuan terhadap Akun Mitra Usaha. Pembekuan tidak akan diteruskan
          secara tidak wajar apabila PT Hidup Mudah Indonesia memutuskan bahwa
          hal-hal yang mengakibatkan terjadinya pembekuan telah diselesaikan.
          <br></br>
          <br></br>
          Jika Anda memiliki pertanyaan Batasan Tanggung Jawab ini, Anda dapat
          menghubungi kami melalui email: info@mudahindonesia.com
          <br></br>
          <br></br>
          Diperbaharui pada tanggal: 3 September 2023
        </Typography>
      </Paper>
    </Modal>
  );
};

export default TncBtj;
