import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// import auth components
import { useUserAuth } from "../../context/UserAuthContext";

const Logout = () => {
  const { logOut } = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      logOut().then(navigate("/login", { replace: true }));
    } catch (e) {
      console.log(e.message);
    }
  }, []);

  return <div></div>;
};

export default Logout;
