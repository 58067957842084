import React, { useState } from 'react';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { storage } from '../../../firebase';

// import mui components
import {
  Button,
  Box,
  CircularProgress,
  Grid,
  Modal,
  Paper,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// import static assests
import IdPhoto from '../../../assets/kyc-help.png';
import SelfiePhoto from '../../../assets/kyc-help2.png';

// import auth components
import { useUserAuth } from '../../../context/UserAuthContext';

const KYCUpload = ({ handleNext, updateKycData }) => {
  const [idImage, setIdImage] = useState(null);
  const [idImageURL, setIdImageURL] = useState(null);
  const [selfieImage, setSelfieImage] = useState(null);
  const [selfieImageURL, setSelfieImageURL] = useState(null);

  const { user } = useUserAuth();

  const [idUploadModalOpen, setIdUploadModalOpen] = useState(false);
  const [selfieUploadModalOpen, setSelfieUploadModalOpen] = useState(false);
  const [photosUploading, setPhotosUploading] = useState(false);

  const handleIdUploadModalOpen = () => setIdUploadModalOpen(true);
  const handleIdUploadModalClose = () => setIdUploadModalOpen(false);

  const handleSelfieUploadModalOpen = () => setSelfieUploadModalOpen(true);
  const handleSelfieUploadModalClose = () => setSelfieUploadModalOpen(false);

  const formHandler = async (e) => {
    e.preventDefault();

    try {
      setPhotosUploading(true);
      // upload to firebase based on user id
      const idImageRef = ref(storage, `kyc/${user.uid}/idImage`);
      await uploadBytes(idImageRef, idImage).then((snapshot) => {
        console.log('Uploaded ID image!');
        getDownloadURL(idImageRef).then((url) => {
          updateKycData('idImageURL', url);
        });
      });

      const selfieImageRef = ref(storage, `kyc/${user.uid}/selfieImage`);
      await uploadBytes(selfieImageRef, selfieImage).then((snapshot) => {
        console.log('Uploaded selfie image!');
        getDownloadURL(selfieImageRef).then((url) => {
          updateKycData('selfieImageURL', url);
        });
      });

      setPhotosUploading(false);

      handleNext();
    } catch (e) {
      console.log(e.message);
    }
  };

  const idImageHandler = (e) => {
    e.preventDefault();
    setIdImage(e.target.files[0]);
    handleIdUploadModalClose();
  };

  const selfieImageHandler = (e) => {
    e.preventDefault();
    setSelfieImage(e.target.files[0]);
    handleSelfieUploadModalClose();
  };

  return (
    <>
      <Box component="form" onSubmit={formHandler}>
        <Grid container justifyContent="center" sx={{ width: 1 }}>
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sx={{ width: 1 }}
          >
            <Typography sx={{ mt: 1 }}>Foto KTP</Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sx={{ width: 1 }}
          >
            {idImage ? (
              <Box
                component="img"
                src={URL.createObjectURL(idImage)}
                sx={{ width: '60%', borderRadius: '20px' }}
              ></Box>
            ) : (
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  height: 100,
                  backgroundColor: 'primary.mudahLightGrey',
                  borderColor: 'primary.mudahDarkGrey',
                }}
                onClick={handleIdUploadModalOpen}
              >
                <Typography sx={{ color: 'grey' }}>Ambil Foto KTP</Typography>
              </Button>
            )}
          </Grid>
          {idImage && (
            <Grid
              item
              container
              justifyContent="center"
              xs={12}
              sx={{ width: 1 }}
            >
              <Button
                onClick={handleIdUploadModalOpen}
                sx={{ borderRadius: '25px', width: '50%' }}
              >
                <Typography sx={{ color: 'primary.light' }}>
                  Ulang Foto KTP
                </Typography>
              </Button>
            </Grid>
          )}

          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sx={{ width: 1, mt: 2 }}
          >
            <Typography sx={{ mt: 1 }}>Foto Diri dengan KTP</Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sx={{ width: 1 }}
          >
            {selfieImage ? (
              <Box
                component="img"
                src={URL.createObjectURL(selfieImage)}
                sx={{ width: '60%', borderRadius: '20px' }}
              ></Box>
            ) : (
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  height: 100,
                  backgroundColor: 'primary.mudahLightGrey',
                  borderColor: 'primary.mudahDarkGrey',
                }}
                onClick={handleSelfieUploadModalOpen}
              >
                <Typography sx={{ color: 'grey' }}>
                  Ambil Foto Diri Dengan KTP
                </Typography>
              </Button>
            )}
          </Grid>
          {selfieImage && (
            <Grid
              item
              container
              justifyContent="center"
              xs={12}
              sx={{ width: 1 }}
            >
              <Button
                onClick={handleSelfieUploadModalOpen}
                sx={{ borderRadius: '25px', width: '50%' }}
              >
                <Typography sx={{ color: 'primary.light' }}>
                  Ulang Foto Diri Dengan KTP
                </Typography>
              </Button>
            </Grid>
          )}

          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sx={{ width: 1 }}
          >
            {photosUploading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                fullWidth
                type="submit"
                sx={{ height: '50px', borderRadius: '25px', mt: 2 }}
                disabled={!idImage || !selfieImage}
              >
                <Typography sx={{ color: 'primary.mudahWhite' }}>
                  Selanjutnya
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>

      {/* Modal - ID Upload */}
      <Modal open={idUploadModalOpen} onClose={handleIdUploadModalClose}>
        <Paper
          sx={{
            mx: 'auto',
            mt: '10vh',
            p: 4,
            width: { xs: '80vw', md: 500 },
            backgroundColor: 'primary.mudahWhite',
          }}
        >
          <Grid container justifyContent="center" spacing={3}>
            <Grid item container justifyContent="flex-end" xs={12}>
              <Grid item>
                <CloseIcon
                  onClick={handleIdUploadModalClose}
                  sx={{ cursor: 'pointer' }}
                />
              </Grid>
            </Grid>

            <Grid item container justifyContent="center" xs={12}>
              <Box
                component="img"
                src={IdPhoto}
                sx={{ width: { xs: 1, md: 400 } }}
              ></Box>
            </Grid>

            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Typography variant="h6" fontWeight="bold">
                  Tips Pengambilan Foto KTP
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  1. Pastikan nama dan nomor sesuai dengan yang tertera di KTP.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  2. Pastikan foto KTP menggunakan KTP asli dan bukan fotokopi.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  3. Perhatikan pencahayaan serta kualitas kamera dengan baik.
                </Typography>
              </Grid>
            </Grid>

            <Grid item container justifyContent="center" xs={12}>
              <Grid item xs={12}>
                <Button
                  component="label"
                  variant="contained"
                  fullWidth
                  sx={{ borderRadius: '25px' }}
                >
                  Ambil Foto KTP
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/*"
                    capture="environment"
                    onChange={idImageHandler}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      {/* Modal - Selfie Upload */}
      <Modal
        open={selfieUploadModalOpen}
        onClose={handleSelfieUploadModalClose}
      >
        <Paper
          sx={{
            mx: 'auto',
            mt: '10vh',
            p: 4,
            width: { xs: '80vw', md: 500 },
            backgroundColor: 'primary.mudahWhite',
          }}
        >
          <Grid container justifyContent="center" spacing={2}>
            <Grid item container justifyContent="flex-end" xs={12}>
              <Grid item>
                <CloseIcon
                  onClick={handleSelfieUploadModalClose}
                  sx={{ cursor: 'pointer' }}
                />
              </Grid>
            </Grid>

            <Grid item container justifyContent="center" xs={12}>
              <Box
                component="img"
                src={SelfiePhoto}
                sx={{ width: { xs: 1, md: 400 } }}
              ></Box>
            </Grid>

            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Typography variant="h6" fontWeight="bold">
                  Tips Pengambilan Foto Selfie dengan KTP
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Typography variant="body1">
                  1. Pastikan foto diri kamu dengan KTP dan terlihat jelas.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  2. Pastikan wajah tidak tertutup oleh KTP.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  3. Perhatikan foto KTP tidak tertutup oleh jari kamu.
                </Typography>
              </Grid>
            </Grid>

            <Grid item container justifyContent="center" xs={12}>
              <Grid item xs={12}>
                <Button
                  component="label"
                  variant="contained"
                  fullWidth
                  sx={{ borderRadius: '25px' }}
                >
                  Ambil Foto Diri Dengan KTP
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/*"
                    capture="environment"
                    onChange={selfieImageHandler}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
};

export default KYCUpload;
