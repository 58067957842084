import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// import auth components
import { RecaptchaVerifier } from 'firebase/auth';
import { auth } from '../../../firebase';
import { useUserAuth } from '../../../context/UserAuthContext';

// import mui components
import {
  Button,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

// import API
import API from '../../../api';

const LoginForm = ({ handleNext, setUserPhoneNumber }) => {
  const [validNumber, setValidNumber] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { signIn } = useUserAuth();

  const phoneValidation = (e) => {
    const reg = new RegExp(e.target.pattern);
    let numberIsValid = reg.test(e.target.value);
    setValidNumber(numberIsValid);
    numberIsValid ? setPhoneNumber(e.target.value) : setPhoneNumber('');
  };

  const formHandler = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const phoneWithCountryCode =
      '+62' + phoneNumber.slice(1, phoneNumber.length);

    try {
      await API.post(
        '/merchants/whitelist/by-phone-number',
        {
          phone_number: phoneWithCountryCode,
        },
        {
          timeout: 7000,
        }
      )
        .then((response) => {
          console.log("whitelisted");
        })
        .catch((error) => {
          alert(
            'Nomor telepon belum bisa digunakan untuk mendaftar. Tunggu undangan dari kami ya!'
          );
          console.log(error);
          throw new Error('Phone number is not whitelisted');
        });

      window.recaptchaVerifier = new RecaptchaVerifier(
        'sign-in-button',
        {
          size: 'invisible',
        },
        auth
      );

      window.confirmationResult = await signIn(
        phoneWithCountryCode,
        window.recaptchaVerifier
      );

      setUserPhoneNumber(phoneNumber);
      setIsLoading(false);
      handleNext();
    } catch (e) {
      console.log(e.message);
      setIsLoading(false);
    }
  };

  return (
    <Box component="form" onSubmit={formHandler} sx={{ width: 1, height: 1 }}>
      <Grid container justifyContent="center">
        <Grid item container justifyContent="center" xs={12}>
          <TextField
            required
            id="phone-number"
            name="phone number"
            label="Nomor Telepon"
            placeholder="Contoh: 0812xxxxxxxx"
            fullWidth
            inputProps={{
              inputMode: 'numeric',
              pattern: '^[0-9]{8,13}$',
              minLength: 8,
              maxLength: 13,
            }}
            sx={{
              mt: 8,
            }}
            onChange={phoneValidation}
          />
        </Grid>
        <Grid item container justifyContent="center" xs={12}>
          <Button
            variant="contained"
            fullWidth
            disabled={!validNumber || isLoading}
            type="submit"
            sx={{ height: '50px', borderRadius: '25px', mt: 4 }}
          >
            {isLoading ? (
              <CircularProgress sx={{ height: '20px' }} />
            ) : (
              <Typography sx={{ color: 'primary.mudahWhite' }}>
                Masuk
              </Typography>
            )}
          </Button>
        </Grid>
        <Grid item container justifyContent="center" xs={12}>
          <Link to="/register" style={{ textDecoration: 'none' }}>
            <Typography
              variant="body2"
              align="center"
              sx={{ mt: 4 }}
              to="/register"
            >
              Belum memiliki akun Mudah Indonesia?{' '}
              <Box
                component="span"
                sx={{
                  color: '#0049ad',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                }}
              >
                Daftar di sini
              </Box>
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginForm;
