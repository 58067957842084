import React from 'react';
import { useNavigate } from 'react-router-dom';

// import mui components
import { Box, Button, Grid, Typography } from '@mui/material';

// import static assests
import SuccessImage from '../../../assets/kyc-success-image.png';

const KYCSuccess = () => {
  const navigate = useNavigate();

  const formHandler = (e) => {
    e.preventDefault();
    navigate('/');
    window.location.reload();
  };

  return (
    <>
      <Box component="form" onSubmit={formHandler} justifyContent="center">
        <Grid container justifyContent="center" spacing={2} sx={{ width: 1 }}>
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sx={{ width: 1 }}
          >
            <Box sx={{ justifyContent: 'center' }}>
              <Box
                component="img"
                src={SuccessImage}
                alt="KYC success"
                sx={{ maxWidth: { xs: '300px', md: '550px' } }}
              ></Box>
            </Box>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sx={{ width: 1 }}
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ textAlign: 'center' }}
            >
              Permintaan verifikasi telah diterima!
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sx={{ width: 1 }}
          >
            <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
              Kami akan memproses permintaan verifikasi kamu dalam waktu 1x24
              jam.
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sx={{ width: 1 }}
          >
            <Button
              variant="contained"
              fullWidth
              type="submit"
              sx={{ height: '50px', borderRadius: '25px' }}
            >
              <Typography variant="h6" sx={{ color: 'primary.mudahWhite' }}>
                Selesai
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default KYCSuccess;
