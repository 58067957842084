import { queryClient, useMutation, useQuery } from '@tanstack/react-query';

import {
  collection,
  doc,
  query,
  where,
  getDocs,
  orderBy,
} from 'firebase/firestore';
import { db } from '../../firebase';

// import auth components
import { useUserAuth } from '../../context/UserAuthContext';

export const useGetTransactions = () => {
  const { user } = useUserAuth();

  return useQuery({
    queryKey: ['transactions'],
    // queryFn: async () => {
    //   const { data } = await axios({
    //     method: 'get',
    //     url: `${baseURL}/transactions`,
    //     headers: {
    //       'Firebase-Token': idToken,
    //     },
    //   });
    //   return data;
    // },
    queryFn: async () => {
      const q = query(
        collection(db, 'transactions'),
        where('userRef', '==', doc(db, 'users', user.uid)),
        orderBy('createdAt', 'desc')
      );

      const querySnapshot = await getDocs(q);
      const transactions = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return transactions;
    },
  });
};
