import React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import {
  signInWithPhoneNumber,
  signOut,
  onAuthStateChanged,
  updateProfile,
} from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const [pinAuthenticated, setPinAuthenticated] = useState(false);

  // Data cache
  const [userDataCache, setUserDataCache] = useState(undefined);
  const [userPreferenceCache, setUserPreferenceCache] = useState(null);
  const [userStaticQrisCache, setUserStaticQrisCache] = useState('');
  const [userStaticQrisPDFCache, setUserStaticQrisPDFCache] = useState('');

  const signIn = (phoneNumber, appVerifier) => {
    return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        return confirmationResult;
        // ...
      })
      .catch((error) => {
        return error;
      });
  };

  const verifyOTP = (confirmationResult, otp) => {
    return confirmationResult
      .confirm(otp)
      .then((result) => {
        // User signed in successfully.
        setUser(result.user);
        return result.user;
        // ...
      })
      .catch((error) => {
        return error;
      });
  };

  const logOut = () => {
    setUserDataCache(null);
    setUserPreferenceCache(null);
    setPinAuthenticated(false);
    if (user === undefined) {
      return 1;
    }
    return signOut(auth);
  };

  // Update default user attributes
  const updateDefaultProfile = (updates) => {
    updateProfile(user, updates);
    updateUserProfile(updates);
  };

  // Update custom user attributes
  const updateUserProfile = (updates) => {
    updateDoc(doc(db, 'users', user.uid), updates);
  };

  const updateUserProfileFunc = (updates) => {
    return updateDoc(doc(db, 'users', user.uid), updates);
  };

  const createNewUser = () => {
    setDoc(doc(db, 'users', user.uid), {
      phoneNumber: user.phoneNumber,
      uid: user.uid,
      verified: 'none',
    });
  };

  const clearUserCache = () => {
    setUserDataCache(null);
    setUserPreferenceCache(null);
  };

  const userData = () => {
    if (userDataCache !== null) {
      if (userDataCache !== undefined) {
        return userDataCache;
      }
    }

    const data = getDoc(doc(db, 'users', user.uid))
      .then((doc) => {
        return doc.data();
      })
      .catch((error) => {
        return error;
      });
    setUserDataCache(data);
    return data;
  };

  const userPreference = () => {
    if (userPreferenceCache) {
      return userPreferenceCache;
    }

    const data = getDoc(doc(db, 'user_preferences', user.uid))
      .then((doc) => {
        return doc.data();
      })
      .catch((error) => {
        return error;
      });
    setUserPreferenceCache(data);
    return data;
  };

  const refreshUserData = async () => {
    setUserDataCache(null);
    setUserPreferenceCache(null);

    await userData();
    await userPreference();
  };

  const setUserPreference = (updates) => {
    updateDoc(doc(db, 'user_preferences', user.uid), updates);
  };

  const getUserToken = () => {
    return user.getIdToken().then((token) => {
      return token;
    });
  };

  const setUserStaticQrisData = (updates) => {
    setUserStaticQrisCache(updates);
  };

  const getUserStaticQrisData = () => {
    return userStaticQrisCache;
  };

  const setUserStaticQrisPDFData = (updates) => {
    setUserStaticQrisPDFCache(updates);
  };

  const getUserStaticQrisPDFData = () => {
    return userStaticQrisPDFCache;
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        pinAuthenticated,
        setPinAuthenticated,
        setUser,
        userData,
        userPreference,
        setUserPreference,
        signIn,
        verifyOTP,
        logOut,
        updateDefaultProfile,
        updateUserProfile,
        updateUserProfileFunc,
        createNewUser,
        getUserToken,
        setUserStaticQrisData,
        getUserStaticQrisData,
        setUserStaticQrisPDFData,
        getUserStaticQrisPDFData,
        clearUserCache,
        refreshUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserAuth = () => {
  return useContext(UserContext);
};
