import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUserAuth } from '../../context/UserAuthContext';

const ProtectedRoute = ({ children }) => {
  const { user } = useUserAuth();

  if (user === undefined) {
    return <></>;
  }
  if (user) {
    return children;
  }
  return <Navigate to="/login" />;
};

export default ProtectedRoute;
