import React from 'react';
import { Grid, Box, Typography } from '@mui/material';

import ShieldLogo from '../../../assets/shield.png';

const BigFlipCopywriting = () => {
  return (
    <Grid container justifyContent="space-between" spacing={2}>
      <Grid item container alignItems="center" xs="auto">
        <Box component="img" src={ShieldLogo} sx={{ maxWidth: '25px' }}></Box>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="body2" xs={{ color: 'primary.mudahLightGrey' }}>
          <Typography variant="body2" fontWeight="bold" display="inline">
            Kami bekerja sama dengan BigFlip
          </Typography>{' '}
          yang diawasi & terlisensi Bank Indonesia agar semua transaksi Anda
          aman dan cepat.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BigFlipCopywriting;
