import React from 'react';
import { useNavigate } from 'react-router-dom';

// import mui components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';

// import static assests
import SuccessImage from '../../../assets/registration-success-default.png';
import SuccessImageMobile from '../../../assets/registration-success-mobile.png';

const RegisterSuccess = () => {
  const navigate = useNavigate();

  const formHandler = (e) => {
    e.preventDefault();
    navigate('/');
    window.location.reload();
  };

  return (
    <Box
      component="form"
      onSubmit={formHandler}
      alignItems="center"
      sx={{ width: 1, height: 1 }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: '80%' }}
      >
        <Grid item container justifyContent="center" xs={12} sx={{ width: 1 }}>
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sx={{ width: 1 }}
          >
            <Hidden mdDown>
              <Box
                component="img"
                src={SuccessImage}
                alt="Registration success"
                sx={{ width: 1 }}
              ></Box>
            </Hidden>
            <Hidden mdUp>
              <Box
                component="img"
                src={SuccessImageMobile}
                alt="Registration success"
                sx={{ width: 1, maxWidth: '75%' }}
              ></Box>
            </Hidden>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sx={{ width: 1 }}
          >
            <Typography variant="h2" fontWeight="bold">
              Mulai dengan QRIS
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sx={{ width: 1 }}
          >
            <Typography variant="h4" sx={{ textAlign: 'center', mt: 1 }}>
              Kamu juga dapat catat transaksi dan dapatkan notifikasi transaksi
              QRIS. Yuk Mulai!
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ width: 1 }}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            sx={{ height: '50px', borderRadius: '25px', mt: 4 }}
          >
            <Typography sx={{ color: 'primary.mudahWhite' }}>
              Selesai
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegisterSuccess;
