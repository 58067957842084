import React from "react";
import { Link } from "react-router-dom";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import { FaRegWindowClose } from "react-icons/fa";

// import static assets
import "../css/MainContainer.css";
import KYCKV from "../../assets/kyc-kv.png";

// import components
import MudahCopyright from "../components/MudahCopyright";

// import mui components
import { Box, Button, Grid, Hidden, Paper, Typography } from "@mui/material";

const Verification = () => {
  return (
    <>
      <Sidebar />
      <div className="MainContainer">
        <Header pageTitle="Verifikasi" />
        <Paper elevation={4} sx={{ mt: "30px", p: "35px" }}>
          <Box sx={{ mx: "auto" }}>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  src={KYCKV}
                  alt="KYC Key Visual"
                  sx={{ mx: "auto", maxWidth: "100%" }}
                />
              </Grid>

              <Grid item container xs={12} md={6} justifyContent="center">
                <Grid item xs={12}>
                  <Typography variant="h5" fontWeight="bold">
                    Buat QRIS di Mudah sekarang. KYC hanya 5 menit!
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mt: { xs: 1, md: 2 } }}>
                    ✓{" "}
                    <Hidden mdDown>
                      Pembayaran pakai QRIS bisa dicairkan ke bank/e-wallet.
                    </Hidden>
                    <Hidden mdUp>Bisa dicairkan ke bank & e-wallet.</Hidden>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mt: { xs: 1, md: 2 } }}>
                    ✓{" "}
                    <Hidden mdDown>
                      Saldo dapat dicairkan secara instant (1-15 menit).
                    </Hidden>
                    <Hidden mdUp>Pencairan saldo QRIS Instan.</Hidden>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mt: { xs: 1, md: 2 } }}>
                    ✓{" "}
                    <Hidden mdDown>
                      Satu QRIS untuk semua pembayaran bank dan dompet digital.
                    </Hidden>
                    <Hidden mdUp>Satu QRIS untuk semua pembayaran.</Hidden>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mt: { xs: 1, md: 2 } }}>
                    ✓{" "}
                    <Hidden mdDown>
                      Semua pembayaran QRIS otomatis tercatat di laporan
                      keuangan.
                    </Hidden>
                    <Hidden mdUp>Otomatis tercatat di laporan keuangan.</Hidden>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mt: { xs: 1, md: 2 } }}>
                    ✓{" "}
                    <Hidden mdDown>
                      Request untuk cetak QRIS langsung dikirim ke alamat
                      usahamu.
                    </Hidden>
                    <Hidden mdUp>QRIS dapat dibantu untuk dicetak.</Hidden>
                  </Typography>
                </Grid>

                <Grid item container xs={12} justifyContent="flex-start">
                  <Button
                    component={Link}
                    to="/kyc"
                    variant="contained"
                    fullWidth
                    sx={{
                      mt: 4,
                      mx: { xs: "auto", md: 0 },
                      borderRadius: 8,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Ajukan Sekarang
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <MudahCopyright />
      </div>
    </>
  );
};

export default Verification;
