import React from 'react';
import { Grid, Box, Typography } from '@mui/material';

import ZapLogo from '../../../assets/zap.png';

const QrisCopywriting = () => {
  return (
    <Grid
      container
      justifyContent="center"
      spacing={2}
      sx={{ maxWidth: { xs: '100%', md: '450px' }, mx: 'auto' }}
    >
      <Grid item container alignItems="center" xs={2}>
        <Box component="img" src={ZapLogo} sx={{ maxWidth: '25px' }}></Box>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="body2" fontWeight="bold" display="inline">
          Nikmati transaksi QRIS Praktis & penarikan Instan{' '}
        </Typography>
        <Typography
          variant="body2"
          display="inline"
          xs={{ color: 'primary.mudahLightGrey' }}
        >
          dengan Biaya MDR 2% hanya pada setiap transaksi berhasil.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default QrisCopywriting;
