import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

// import mui components
import {
  Button,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

const KYCAddress = ({ handleNext, address, updateKycData }) => {
  const [provinceId, setProvinceId] = useState('11');
  const [addressProp, setAddressProp] = useState({ ...address });

  const formHandler = async (e) => {
    e.preventDefault();

    try {
      updateKycData('address', addressProp);

      handleNext();
    } catch (e) {
      console.log(e.message);
    }
  };

  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);

  const handleProvinceChange = (event) => {
    event.preventDefault();
    setAddressProp({ ...addressProp, province: event.target.value });
  };

  const handleCityChange = (event) => {
    event.preventDefault();
    setAddressProp({ ...addressProp, city: event.target.value });
  };

  useEffect(() => {
    const getProvinces = async () => {
      const response = await axios.get(
        'https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json'
      );
      setProvinces(response.data);
    };

    const getCities = async () => {
      const response = await axios.get(
        'https://www.emsifa.com/api-wilayah-indonesia/api/regencies/' +
          provinceId +
          '.json'
      );
      setCities(response.data);
    };

    getProvinces();
    getCities();
  }, [provinceId]);

  return (
    <Box component="form" onSubmit={formHandler}>
      <Grid container justifyContent="center" sx={{ width: 1 }}>
        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          sx={{ width: 1, mt: 4 }}
        >
          <TextField
            required
            id="full-address"
            name="full address"
            label="Alamat Lengkap"
            placeholder="Contoh: Jl. ABC Susu 12 No. 1"
            fullWidth
            onChange={(e) =>
              setAddressProp({ ...addressProp, fullAddress: e.target.value })
            }
          />
        </Grid>

        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          sx={{ width: 1, mt: 2 }}
        >
          <FormControl fullWidth>
            <InputLabel id="province-label">Provinsi</InputLabel>
            <Select
              required
              label="Provinsi"
              id="province"
              fullWidth
              placeholder="Contoh: ACEH"
              value={addressProp.province}
              onChange={handleProvinceChange}
            >
              {provinces.map((province) => (
                <MenuItem
                  key={province.id}
                  value={province.name}
                  onClick={() => setProvinceId(province.id)}
                >
                  {province.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          sx={{ width: 1, mt: 2 }}
        >
          <FormControl fullWidth>
            <InputLabel id="city-label">Kota</InputLabel>
            <Select
              required
              label="Kota/Kabupaten"
              id="city"
              fullWidth
              placeholder="Contoh: Bekasi"
              value={addressProp.city}
              onChange={handleCityChange}
            >
              {cities.map((city) => (
                <MenuItem key={city.id} value={city.name}>
                  {city.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          sx={{ width: 1, mt: 2 }}
        >
          <TextField
            required
            id="district"
            name="district"
            label="Kecamatan"
            placeholder="Contoh: Mekarjaya"
            fullWidth
            onChange={(e) =>
              setAddressProp({ ...addressProp, district: e.target.value })
            }
          />
        </Grid>

        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          sx={{ width: 1, mt: 2 }}
        >
          <TextField
            required
            id="postcode"
            name="postcode"
            label="Kode Pos"
            placeholder="Contoh: 10234"
            fullWidth
            onChange={(e) =>
              setAddressProp({ ...addressProp, postalCode: e.target.value })
            }
          />
        </Grid>

        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          sx={{ width: 1, mt: 4 }}
        >
          <Button
            variant="contained"
            fullWidth
            disabled={
              addressProp.fullAddress === '' ||
              addressProp.province === '' ||
              addressProp.city === '' ||
              addressProp.district === '' ||
              addressProp.postalCode === ''
            }
            type="submit"
            sx={{ height: '50px', borderRadius: '25px' }}
          >
            <Typography sx={{ color: 'primary.mudahWhite' }}>
              Selanjutnya
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default KYCAddress;
