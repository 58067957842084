import React, { useState } from 'react';

// import auth components
import { useUserAuth } from '../../../context/UserAuthContext';

// import mui components
import { Button, Box, Grid, Typography } from '@mui/material';

// import page components
import InputPin from '../InputPin';

// import helper functions
import { cyrb53 } from '../../../helper';

const RegisterOTP = ({ handleNext }) => {
  const [pinValid, setPinValid] = useState(false);
  const [pinNumber, setPinNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { updateUserProfile } = useUserAuth();

  const pinReg = new RegExp('^[0-9]{6}$');

  const pinChangeHandler = (inputNumber) => {
    setPinNumber(inputNumber);
    pinReg.test(pinNumber) ? setPinValid(true) : setPinValid(false);
  };

  const formHandler = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    let hashedPin = cyrb53(pinNumber);
    await updateUserProfile({ pinNumber: hashedPin });

    setIsLoading(false);

    handleNext();
  };

  return (
    <Box component="form" onSubmit={formHandler}>
      <Grid container>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <Typography>Buat kode PIN</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            sx={{
              color: 'primary.mudahDarkGrey',
              mx: 'auto',
            }}
          >
            Digunakan untuk masuk aplikasi dan keamanan bertransaksi
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" xs={12} sx={{ mt: 2 }}>
          <Grid container justifyContent="center" xs={12}>
            <InputPin pinChangeHandler={pinChangeHandler} />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            sx={{ height: '50px', borderRadius: '25px' }}
            disabled={!pinValid}
          >
            <Typography
              sx={{ color: 'primary.mudahWhite' }}
              disabled={isLoading}
            >
              Atur PIN
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegisterOTP;
