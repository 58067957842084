import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';

// import auth components
import { useUserAuth } from '../../../context/UserAuthContext';

// import mui components
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// import page components
import InputOTP from '../InputOTP';

const RegisterOTP = ({ handleNext, phoneNumber, phoneWithCountryCode }) => {
  const [otpValid, setOtpValid] = useState(false);
  const [otpNumber, setOtpNumber] = useState('');
  const [otpUnverified, setOtpUnverified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { verifyOTP } = useUserAuth();
  const navigate = useNavigate();

  const otpReg = new RegExp('^[0-9]{6}$');

  const redactedPhoneNumber = phoneNumber.replace(
    phoneNumber.substring(5, phoneNumber.length - 2),
    '*'.repeat(phoneNumber.length - 7)
  );

  const otpChangeHandler = (pinNumber) => {
    setOtpNumber(pinNumber);
    otpReg.test(otpNumber) ? setOtpValid(true) : setOtpValid(false);
  };

  const formHandler = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      await verifyOTP(window.confirmationResult, otpNumber);

      const q = query(collection(db, 'users'));
      const querySnapshot = await getDocs(q);
      const users = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      let isRegistered = false;
      users.forEach((user) => {
        if (user.phoneNumber === phoneWithCountryCode) {
          isRegistered = true;
        }
      });
      if (isRegistered) {
        alert('Nomor telepon sudah terdaftar');
        setIsLoading(false);
        navigate('/login');
        return;
      }

      setOtpUnverified(false);
      handleNext();
    } catch (e) {
      setOtpUnverified(true);
      console.log(e.message);
    }

    setIsLoading(false);
  };

  return (
    <Box component="form" onSubmit={formHandler} sx={{ width: 1, height: 1 }}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: '80%' }}
      >
        <Grid item container justifyContent="center" sx={{ width: 1 }}>
          <Grid item xs={12}>
            <Typography sx={{ mt: 4 }}>Masukan kode OTP</Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: 'primary.mudahDarkGrey' }}
            >
              Kode OTP telah dikirimkan melalui sms ke {redactedPhoneNumber}
            </Typography>
          </Grid>

          <Grid container justifyContent="center" xs={12} sx={{ mt: 2 }}>
            <InputOTP pinChangeHandler={otpChangeHandler} />
          </Grid>

          {otpUnverified && (
            <Typography
              variant="subtitle2"
              sx={{
                color: 'primary.mudahRed',
                mx: 'auto',
                textAlign: 'center',
              }}
            >
              OTP salah! Harap masukan kembali kode OTP.
            </Typography>
          )}

          <Typography
            variant="subtitle2"
            sx={{
              color: 'primary.mudahDarkGrey',
              mx: 'auto',
              mt: 1,
              textAlign: 'center',
            }}
          >
            Kirim ulang kode OTP?
          </Typography>
        </Grid>

        <Grid item sx={{ width: 1, mt: 4 }}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            sx={{ height: '50px', borderRadius: '25px' }}
            disabled={!otpValid || isLoading}
          >
            <Typography sx={{ color: 'primary.mudahWhite' }}>
              Selanjutnya
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegisterOTP;
