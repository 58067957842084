import React, { useEffect, useState } from 'react';
import Header from '../../layouts/Header';
import Sidebar from '../../layouts/Sidebar';

import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

// import static assets
import '../css/MainContainer.css';

// import components
import MudahCopyright from '../components/MudahCopyright';

// import mui components
import {
  Box,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';

// import auth components
import { useUserAuth } from '../../context/UserAuthContext';

// Hooks
import { useGetNotifications } from '../../hooks/services/notifications';

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [broadcastNotifications, setBroadcastNotifications] = useState([]);

  const [tabValue, setTabValue] = useState(0);

  const { isSuccess, data } = useGetNotifications();

  const { user } = useUserAuth();

  const clearNotifications = () => {
    const notificationInfo = {
      notReadCount: 0,
      isRead: true,
      lastUpdatedAt: new Date(),
    };

    const notificationInfoRef = doc(db, 'notification_infos', user.uid);

    updateDoc(notificationInfoRef, notificationInfo);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (isSuccess) {
      setNotifications(data.notifications);
      setBroadcastNotifications(data.broadcastNotifications);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    clearNotifications();
  }, []);

  return (
    <>
      <Sidebar />
      <div className="MainContainer">
        <Header pageTitle="Notifikasi" />

        <Paper sx={{ mt: 2, py: 2 }}>
          <Box sx={{ mx: 'auto', maxWidth: 750 }}>
            <Box
              sx={{ borderBottom: 1, borderColor: 'divider' }}
              variant="fullwidth"
            >
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                sx={{ justifyContent: 'center', mx: 'auto' }}
              >
                <Tab label="Personal" />
                <Tab label="Broadcast" />
              </Tabs>

              {/* Personal */}
              {tabValue === 0 && (
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table>
                    <TableBody>
                      {notifications.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            my: 1,
                          }}
                        >
                          <TableCell align="left">
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography
                                  variant="body1"
                                  sx={{ fontWeight: 'bold' }}
                                >
                                  {row.title}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="body2">
                                  {row.body}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: 'primary.mudahDarkGrey',
                                    fontStyle: 'italic',
                                  }}
                                >
                                  {row.createdAt.toDate().toLocaleString()}
                                </Typography>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {/* Broadcast */}
              {tabValue === 1 && (
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table>
                    <TableBody>
                      {broadcastNotifications.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            my: 1,
                          }}
                        >
                          <TableCell align="left">
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography
                                  variant="body1"
                                  sx={{ fontWeight: 'bold' }}
                                >
                                  {row.title}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="body2">
                                  {row.body}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: 'primary.mudahDarkGrey',
                                    fontStyle: 'italic',
                                  }}
                                >
                                  {row.createdAt.toDate().toLocaleString()}
                                </Typography>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </Box>
        </Paper>

        <MudahCopyright />
      </div>
    </>
  );
};

export default Notification;
