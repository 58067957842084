import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// import mui components
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// import auth components
import { RecaptchaVerifier } from 'firebase/auth';
import { auth } from '../../../firebase';
import { useUserAuth } from '../../../context/UserAuthContext';

// import API
import API from '../../../api';

// import static data
import bizTypeList from '../../../data/bizTypeList';

const RegisterForm = ({
  handleNext,
  bizName,
  phoneNumber,
  bizType,
  updateFormData,
}) => {
  const [validNumber, setValidNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { signIn } = useUserAuth();

  const phoneValidation = (e) => {
    e.preventDefault();
    const reg = new RegExp(e.target.pattern);
    let numberIsValid = reg.test(e.target.value);
    setValidNumber(numberIsValid);
    if (numberIsValid) {
      updateFormData('phoneNumber', e.target.value);
    }
  };

  const formHandler = async (e) => {
    e.preventDefault();

    if (bizName === '' || phoneNumber === '' || bizType === '') {
      alert('Mohon isi semua kolom yang tersedia');
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    console.log(phoneNumber);

    const phoneWithCountryCode =
      '+62' + phoneNumber.slice(1, phoneNumber.length);

    try {
      await API.post(
        '/merchants/whitelist/by-phone-number',
        {
          phone_number: phoneWithCountryCode,
        },
        {
          timeout: 7000,
        }
      )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          alert(
            'Nomor telepon belum bisa digunakan untuk mendaftar. Tunggu undangan dari kami ya!'
          );
          console.log(error);
          throw new Error('Phone number is not whitelisted');
        });

      window.recaptchaVerifier = new RecaptchaVerifier(
        'sign-up-button',
        {
          size: 'invisible',
        },
        auth
      );

      window.confirmationResult = await signIn(
        phoneWithCountryCode,
        window.recaptchaVerifier
      );;

      updateFormData('bizName', bizName);
      updateFormData('phoneNumber', phoneNumber);
      updateFormData('bizType', bizType);
      updateFormData('phoneWithCountryCode', phoneWithCountryCode);

      setIsLoading(false);

      handleNext();
    } catch (e) {
      console.log(e.message);
      setIsLoading(false);
    }
  };

  return (
    <Box component="form" onSubmit={formHandler}>
      <Grid container justifyContent="space-center" sx={{ height: '90%' }}>
        <Grid item xs={12} sx={{ width: 1 }}>
          <TextField
            required
            id="biz-name"
            name="biz name"
            label="Nama Usaha"
            placeholder="Contoh: PT Jaya Abadi"
            fullWidth
            sx={{
              mt: 4,
            }}
            onChange={(e) => {
              e.preventDefault();
              updateFormData('bizName', e.target.value);
            }}
          />

          <TextField
            required
            id="phone-number"
            name="phone number"
            label="Nomor Telepon"
            placeholder="Contoh: 08xxxxxxxxxx"
            fullWidth
            inputProps={{
              inputMode: 'numeric',
              pattern: '^[0-9]{8,13}$',
              minLength: 8,
              maxLength: 13,
            }}
            sx={{
              mt: 2,
            }}
            onChange={phoneValidation}
          />

          <TextField
            required
            select
            id="biz-type"
            name="biz type"
            label="Jenis Usaha"
            fullWidth
            sx={{
              mt: 2,
            }}
            onChange={(e) => {
              e.preventDefault();
              updateFormData('bizType', e.target.value);
            }}
          >
            {bizTypeList.map((bizType) => (
              <MenuItem value={bizType.name} disabled={bizType.isCategory}>
                {bizType.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sx={{ width: 1, mt: 4 }}>
          <Button
            variant="contained"
            fullWidth
            disabled={!validNumber || isLoading}
            type="submit"
            sx={{ height: '50px', borderRadius: '25px' }}
          >
            {isLoading ? (
              <CircularProgress sx={{ height: '20px' }} />
            ) : (
              <Typography sx={{ color: 'primary.mudahWhite' }}>
                Daftar
              </Typography>
            )}
          </Button>

          <Link to="/login" style={{ textDecoration: 'none' }}>
            <Typography
              variant="body2"
              align="center"
              sx={{ mt: 4 }}
              to="/login"
            >
              Sudah memiliki akun Mudah Indonesia?{' '}
              <Box
                component="span"
                sx={{
                  color: '#0049ad',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                }}
              >
                Masuk di sini
              </Box>
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegisterForm;
