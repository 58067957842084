import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import auth components
import { RecaptchaVerifier } from 'firebase/auth';
import { auth } from '../../../firebase';
import { useUserAuth } from '../../../context/UserAuthContext';

// import mui components
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// import page components
import InputOTP from '../InputOTP';

const LoginOTP = ({ handleNext, userPhoneNumber }) => {
  const [otpValid, setOtpValid] = useState(false);
  const [otpNumber, setOtpNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const { signIn, verifyOTP, userData, logOut } = useUserAuth();

  const otpReg = new RegExp('^[0-9]{6}$');

  const redactedPhoneNumber = userPhoneNumber.replace(
    userPhoneNumber.substring(5, userPhoneNumber.length - 2),
    '*'.repeat(userPhoneNumber.length - 7)
  );

  const otpChangeHandler = (pinNumber) => {
    setOtpNumber(pinNumber);
    otpReg.test(otpNumber) ? setOtpValid(true) : setOtpValid(false);
  };

  const resendOTP = async (e) => {
    e.preventDefault();

    const phoneWithCountryCode =
      '+62' + userPhoneNumber.slice(1, userPhoneNumber.length);

    try {
      let confirmationResult = await signIn(
        phoneWithCountryCode,
        window.recaptchaVerifier
      );
      window.confirmationResult = confirmationResult;
    } catch (e) {
      console.log(e.message);
    }
  };

  const formHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);

    verifyOTP(window.confirmationResult, otpNumber)
      .then((user) => {
        if (user !== undefined) {
          setIsLoading(false);
          handleNext();
        } else {
          alert('Akun anda belum terdaftar, silahkan daftar terlebih dahulu.');
          setIsLoading(false);
          logOut().then(navigate('/register', { replace: true }));
        }
      })
      .catch((err) => {
        console.log(err);
        alert('Kode OTP yang anda masukan salah, harap coba kembali.');
        setIsLoading(false);
      });
  };

  return (
    <Box component="form" onSubmit={formHandler} sx={{ width: 1, height: 1 }}>
      <Grid container justifyContent="center">
        <Grid item container justifyContent="align-left" xs={12} sx={{ mt: 4 }}>
          <Typography>Masukan kode OTP</Typography>
        </Grid>
        <Grid item container justifyContent="align-left" xs={12}>
          <Typography
            variant="subtitle2"
            sx={{ color: 'primary.mudahDarkGrey' }}
          >
            Kode OTP telah dikirimkan melalui sms ke {redactedPhoneNumber}
          </Typography>
        </Grid>

        <Grid item container justifyContent="center" xs={12} sx={{ mt: 2 }}>
          <InputOTP pinChangeHandler={otpChangeHandler} />
        </Grid>

        <Grid item container justifyContent="center" xs={12}>
          <Typography
            variant="subtitle2"
            onClick={resendOTP}
            sx={{
              color: 'primary.mudahDarkGrey',
              mx: 'auto',
              mt: 1,
              textAlign: 'center',
              cursor: 'pointer',
            }}
          >
            Kirim ulang kode OTP?
          </Typography>
        </Grid>

        <Grid item container justifyContent="center" xs={12} sx={{ mt: 4 }}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            sx={{ height: '50px', borderRadius: '25px' }}
            disabled={!otpValid || isLoading}
          >
            {isLoading ? (
              <CircularProgress sx={{ height: '20px' }} />
            ) : (
              <Typography sx={{ color: 'primary.mudahWhite' }}>
                Selanjutnya
              </Typography>
            )}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginOTP;
