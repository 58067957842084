import React from 'react';
import { Grid, Box, Typography } from '@mui/material';

import ZapLogo from '../../../assets/zap.png';

const InstantSetllementCopywriting = () => {
  return (
    <Grid container justifyContent="space-between" spacing={2}>
      <Grid item container alignItems="center" xs="auto">
        <Box component="img" src={ZapLogo} sx={{ maxWidth: '25px' }}></Box>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="body2" xs={{ color: 'primary.mudahLightGrey' }}>
          <Typography variant="body2" fontWeight="bold" display="inline">
            Nikmati penarikan instan
          </Typography>{' '}
          dan praktis dengan Biaya transfer untuk memastikan transaksi tanpa
          hambatan.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default InstantSetllementCopywriting;
